<template>
	<v-navigation-drawer app :right="right" v-model="showDrawer">
		<v-card color="secondary" flat tile dark class="pa-3" v-if="isUserAuthenticated">
			<v-container class="pa-0">
				<v-row no-gutters>
					<v-col cols="12">
						<router-link class="profileLink" to="/Account/Profile">
							<user-avatar-fullname :size="44" :showDisplayName="true" textClass="white-text ml-3"
								:profile="UserProfile"></user-avatar-fullname>
						</router-link>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
		<v-list dense class="pt-0 text-capitalize">
			<v-list-item to="/" color="primary">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-home</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title class="font-weight-regular">Home</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item v-if="!isUserAuthenticated" to="/Login" color="primary">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-sign-in-alt</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title class="font-weight-regular">{{ $t("common.login") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<template v-if="isUserInRoles(['Client', 'ClientSubAccount'])"><v-subheader>{{ $t("ClientSection")
					}}</v-subheader>
				<v-list-item to="/Clients/Offers" color="primary">
					<v-list-item-action>
						<v-icon>fas fa-fw fa-camera-alt</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title class="font-weight-regular">{{ $t("sgp.offers") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item to="/Clients/Orders" color="primary">
					<v-list-item-action>
						<v-icon>fas fa-fw fa-photo-video</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title class="font-weight-regular">{{ $t("common.orders") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
			<template v-if="isUserInRoles(['Photographer', 'BusinessAdmin'])">
				<v-subheader>{{ $t("AdminSection") }}</v-subheader>
				<v-list-item to="/Events" color="primary">
					<v-list-item-action>
						<v-icon>fas fa-fw fa-calendar-star</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title class="font-weight-regular">{{ $t("sgp.events") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item to="/Offers" color="primary">
					<v-list-item-action>
						<v-icon>fas fa-fw fa-camera-alt</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title class="font-weight-regular">{{ $t("sgp.offers") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

			</template>
			<v-list-item to="/Orders" color="primary" v-if="isUserInRoles(['BusinessAdmin'])">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-photo-video</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title class="font-weight-regular">{{ $t("common.orders") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item to="/Shop" color="primary" v-if="isUserInRoles(['BusinessAdmin'])">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-cash-register</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title class="font-weight-regular">{{ $t("common.shop") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item v-if="isUserInRole('UserManager')" to="/Users" color="primary">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-users</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title class="font-weight-regular">{{ $t("UserManagement") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<template v-if="isUserInRoles(['Photographer', 'BusinessAdmin', 'Editor'])">
				<v-list-item to="/RecoEvents" color="primary">
					<v-list-item-action>
						<v-icon>fas fa-fw fa-draw-square</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title class="font-weight-regular">{{ $t("sgp.recoEvents") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item to="/FacePerson" color="primary">
					<v-list-item-action>
						<v-icon>far fa-id-card-alt</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title class="font-weight-regular">{{ $t("Face Directory") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item to="/1000MigliaEvents" color="primary">
					<v-list-item-action>
						<v-icon>fas fa-fw fa-cars</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title class="font-weight-regular">{{ $t("sgp.1000Miglia") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
			<v-list-item v-if="isUserInRole('SystemAdmin')" to="/SystemSettings" color="primary">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-cogs</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title class="font-weight-regular">{{ $t("SystemAdmin") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider inset v-if="isUserAuthenticated"></v-divider>
			<v-list-item v-if="isUserAuthenticated" @click="DoSignout">
				<v-list-item-action>
					<v-icon>fas fa-fw fa-sign-out-alt</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title class="font-weight-regular">{{ $t("common.logout") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item to="/Support">
				<v-list-item-action>
					<v-icon>far fa-life-ring</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title class="font-weight-regular">{{ $t("support") }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserAvatarFullname from "@/components/Shared/UI/UserAvatarFullname";

export default {
	name: "side-bar",
	components: { UserAvatarFullname },
	data() {
		return {
			menuLinks: [{ title: "Home", icon: "fas fa-fw fa-home", to: "/" }],
			right: false,
			showDrawer: true,
		};
	},
	computed: {
		...mapGetters([
			"UserProfile",
			"isUserAuthenticated",
			"isUserInRole",
			"isUserInRoles",
		]),
	},
	methods: {
		...mapActions(["SignOut"]),

		ToggleDrawer() {
			this.showDrawer = !this.showDrawer;
		},

		DoSignout() {
			this.SignOut();
			this.$router.push("/Login");
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

a.profileLink {
	color: white;

	&:hover {
		color: $primary;
	}

	&>div {
		display: flex;
		align-items: center;
	}
}
</style>

<i18n>
{
	"it": {
		"AdminSection": "Area Admin",
		"ClientSection": "Area Clienti",
		"SystemAdmin": "System Admin",
		"UserManagement": "Gestione Utenti",
		"support": "Assistenza"
	},
	"it": {
		"ClientSection": "Client Area",
		"support": "Support"
	}
}
</i18n>
