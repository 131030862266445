<template>
	<v-dialog
		:value="value"
		@input="$emit('input', $event)"
		max-width="500px"
		@keydown.esc="CloseDialog"
		persistent
		no-click-animation
	>
		<SimpleFormCard
			ref="formAddEditSynchronizationTasks"
			:formContent="formAddEditSynchronizationTasks"
			:metadata="metadata"
			:metadataLoaded="metadataLoaded"
			:mode="mode"
			@submitForm="AddOrSave"
			:showCancelButton="false"
			:locali18n="$i18n"
			:initialValues="itemDetails"
		>
			<template slot="card-title">
				<div
					class="
						primary
						white--text
						px-5
						py-3
						d-flex
						align-center
						justify-space-between
					"
				>
					<span class="headline" style="word-break: normal">{{
						mode == 1
							? $t("addFtpConnection_Header")
							: $t("editFtpConnectionDialog_Header")
					}}</span>
					<v-btn icon dark @click="CloseDialog">
						<v-icon>close</v-icon>
					</v-btn>
				</div>
			</template>
		</SimpleFormCard>
	</v-dialog>
</template>
<script>
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";
import metadata from "@/mixins/metadata";

export default {
	$_veeValidate: { validator: "AddEditFtpConnection" },
	components: { SimpleFormCard },
	mixins: [metadata],
	data() {
		return {
			formAddEditSynchronizationTasks: new CrudFormData(
				"formAddEditSynchronizationTasks",
				[
					"TaskName",
					"SyncFtpFolder",
					{
						type: "v-select",
						FieldName: "FtpConnectionId",
						items: [],
						"item-text": "Name",
						"item-value": "ConnectionId",
					},
					{
						type: "v-select",
						FieldName: "EventId",
						items: [],
						"item-text": "Name",
						"item-value": "EventId",
					},
				]
			),

			ClientAccounts: [],
			itemDetails: {},
		};
	},
	props: {
		value: { type: Boolean, required: true },
		mode: { type: Number, required: true },
		TaskId: { type: Number },
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			if (!val) this.ClearNewUserDialogForm();
		},
		TaskId: async function (val) {
			this.$log.debug("watch TaskId");
			this.$log.debug(val);

			if (val) {
				this.itemDetails = await this.StorageSynchronizationTasksService.Get(val);
			}
		},
	},
	created() {
		this.StorageSynchronizationTasksService = new CrudClient(
			"SGP/FTP/StorageSynchronizationTasks"
		);

		this.FtpConnectionsService = new CrudClient("SGP/FTP/Connections");

		this.SgpEventsService = new CrudClient("SGP/Events");
	},
	async mounted() {
		this.$log.debug("Add/Edit StorageSynchronizationTasks Dialog mounted");
		await this.LoadMetadata(this.StorageSynchronizationTasksService);
		await this.LoadFtpConnections();
		await this.LoadEvents();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async AddOrSave(dto) {
			this.formAddEditSynchronizationTasks.FormLoading = true;

			try {
				if (this.mode === 1) {
					await this.StorageSynchronizationTasksService.Post(null, dto);
					this.snackSuccess({ Text: this.$t("common.success.addItem") });
				} else {
					await this.StorageSynchronizationTasksService.Patch(
						this.TaskId,
						dto,
						false
					);
					this.snackSuccess({ Text: this.$t("common.success.updateData") });
				}
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.formAddEditSynchronizationTasks.FormLoading = false;
				this.CloseDialog();
				this.$emit("updated");
			}
		},

		ClearNewUserDialogForm() {
			if (this.$refs.formAddEditSynchronizationTasks) {
				this.$refs.formAddEditSynchronizationTasks.ResetForm();
			}
		},

		CloseDialog() {
			this.$emit("input", false);
		},

		async LoadFtpConnections() {
			try {
				const items = (
					await this.FtpConnectionsService.GetPaged({
						skip: 0,
						limit: 0,
						fields: "*",
					})
				).Data;

				this.formAddEditSynchronizationTasks.Fields.find(
					(f) => f.FieldName === "FtpConnectionId"
				).items = [...items].sort();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
			}
		},
		async LoadEvents() {
			try {
				const items = (
					await this.SgpEventsService.GetPaged({
						skip: 0,
						limit: 0,
						fields: "EventId, Name",
						orderBy: "EventId:desc",
					})
				).Data;

				this.formAddEditSynchronizationTasks.Fields.find(
					(f) => f.FieldName === "EventId"
				).items = [...items];
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
			}
		},
	},
};
</script>
<i18n>
{
	"it": {
		"addFtpConnection_Header": "Nuova attività di sincronizzazione",
		"editFtpConnectionDialog_Header": "Modifica l'attività di sincronizzazione",
		"formAddEditSynchronizationTasks":
		{
			"SyncFtpFolder":"Cartella remota",
			"FtpConnectionId": "Connessione FTP",
			"EventId": "Evento",
			"TaskName": "Nome"
		}
	}
}
</i18n>
