import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"

import { captureRouterError } from "@/utilities/Sentry"

import Login from "@/views/Base/Login.vue"
import RecoverPassword from "@/views/Base/RecoverPassword.vue"
import ResetPassword from "@/views/Base/ResetPassword.vue"
import Error404 from "@/views/Base/Error404.vue"
import SystemSettingRoutes from "@/views/SystemSettings/routes"
import UserManagementRoutes from "@/views/Users/routes"
import AccountRoutes from "@/views/Account/routes"
import EventsRoutes from "@/views/Events/routes"
import EditorRoutes from "@/views/Events/routesEditor"
import ClientAreaRoutes from "@/views/ClientArea/routes"
import ShopAdminRoutes from "@/views/Shop/routes"

Vue.use(VueRouter)

const routes = [
	...SystemSettingRoutes,
	...UserManagementRoutes,
	...AccountRoutes,
	...EventsRoutes,
	...EditorRoutes,
	...ClientAreaRoutes,
	...ShopAdminRoutes,
	{
		path: "/",
		name: "Home",
		component: Home
	},
	{
		path: "/login",
		name: "login",
		component: Login
	},
	{
		path: "/RecoverPassword",
		name: "recoverPassword",
		component: RecoverPassword
	},
	{
		path: "/ResetPassword",
		name: "resetPassword",
		component: ResetPassword
	},
	{
		path: "/Support",
		name: "Support",
		component: () => import(/* webpackChunkName: "staticPAges" */ "../views/StaticContent/Support.vue")
	},
	{
		path: "/Privacy",
		name: "Privacy",
		component: () => import(/* webpackChunkName: "staticPAges" */ "../views/StaticContent/Privacy.vue")
	},
	{
		path: "/terms-conditions",
		name: "Terms",
		component: () => import(/* webpackChunkName: "staticPAges" */ "../views/StaticContent/TermsOfUse.vue")
	},
	{
		path: "/Approver/:offerKey",
		name: "ApproveMedia",
		component: () => import(/* webpackChunkName: "staticPAges" */ "../views/ThirdPartyApprover/ApproveOfferMedia.vue")
	},
	{
		path: "/error/404",
		component: Error404
	},
	{
		path: "*",
		redirect: to => {
			// the function receives the target route as the argument
			captureRouterError(to);
			return "/error/404";
		}
	}
]

const router = new VueRouter({
	mode: "history",
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	},
	base: process.env.BASE_URL,
	routes
})

export default router
