const routes = [
	{
		path: "/FacePerson",
		component: () => import(/* webpackChunkName: "EventsView" */ "./FacePerson.vue")
	},
	{
		path: "/RecoEvents",
		component: () => import(/* webpackChunkName: "EventsView" */ "./FaceRecoEventsList.vue")
	},
	{
		path: "/RecoEvents/:EventId",
		component: () => import(/* webpackChunkName: "EventsView" */ "./FaceRecoEventDetails.vue")
	},
	{
		path: "/1000MigliaEvents",
		component: () => import(/* webpackChunkName: "1000Miglia" */ "./1000Miglia/1000MigliaEventsList.vue")
	},
	{
		path: "/1000MigliaEvents/:EventId",
		component: () => import(/* webpackChunkName: "1000Miglia" */ "./1000Miglia/1000MigliaEventDetails.vue")
	},
	{
		path: "/1000MigliaEvents/:EventId/Cars",
		component: () => import(/* webpackChunkName: "1000Miglia" */ "./1000Miglia/EventCars.vue")
	},

];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: ["Photographer", "BusinessAdmin", "Editor"]
	};
}
);

export default routes;
