<template>
	<div>
		<vue-headful :title="$t('pageTitle')" :description="$t('titleDec')" />

		<v-row>
			<v-col cols="12" sm="6" class="offset-sm-3">
				<v-card>
					<v-breadcrumbs :items="breadcrumbsItems">
            <template v-slot:divider>
						<v-icon class="secondary--text text--lighten-2"
							>fas fa-caret-right</v-icon
						>
            </template>
					</v-breadcrumbs>
					<v-col primary-title class="secondary white--text px-6 py-2">
						<div class="headline">{{ $t("pageTitle") }}</div>
						<div class="title">{{ $t("titleDec") }}</div>
					</v-col>

					<div class="pa-5">
						<form v-if="!showSuccessMessage">
							<v-row>
								<v-col cols="12">
									<v-text-field
										ref="password"
										name="password"
										:label="$t('password')"
										data-vv-name="password"
										:data-vv-as="$t('password')"
										data-vv-delay="300"
										min="6"
										v-validate="'required|min:6|max:100'"
										v-model="user.password"
										:error-messages="errors.collect('password')"
										prepend-icon="lock"
										:append-icon="isPasswordVisible ? 'visibility' : 'visibility_off'"
										@click:append="isPasswordVisible = !isPasswordVisible"
										:type="isPasswordVisible ? 'text' : 'password'"
									></v-text-field>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12">
									<v-text-field
										:label="$t('password_confirmation')"
										data-vv-name="password_confirmation"
										:data-vv-as="$t('password_confirmation')"
										data-vv-delay="300"
										target="password"
										v-validate="'required|confirmed:password'"
										v-model="user.password_confirmation"
										:error-messages="errors.collect('password_confirmation')"
										prepend-icon="lock"
										:append-icon="isPasswordVisible2 ? 'visibility' : 'visibility_off'"
										@click:append="isPasswordVisible2 = !isPasswordVisible2"
										:type="isPasswordVisible2 ? 'text' : 'password'"
									></v-text-field>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12">
									<v-btn
										@click="submit"
										color="primary"
										:loading="loading"
										large
										block
										>{{ $t("resetPassword") }}</v-btn
									>
								</v-col>
							</v-row>
						</form>
					</div>

					<v-alert :value="true" v-if="showSuccessMessage" type="success">{{
						$t("resetSuccess")
					}}</v-alert>

					<v-alert :value="true" v-if="showErrorMessage" type="error">{{
						errorMessage
					}}</v-alert>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import Account from "@/services/account";
export default {
	name: "Login",
	$_veeValidate: { validator: "new" },
	data: () => ({
		loading: false,
		user: {
			password: "",
			password_confirmation: "",
		},
		isPasswordVisible: false,
		isPasswordVisible2: false,
		showSuccessMessage: false,
		showErrorMessage: false,
		errorMessage: "",
		breadcrumbsItems: [
			{
				text: "Home",
				disabled: false,
				exact: true,
				to: "/",
			},
			{
				text: "Login",
				disabled: false,
				exact: true,
				to: "/Login",
			},
		],
	}),
	mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/ResetPassword",
		});
	},
	methods: {
		async submit() {
			const result = await this.$validator.validate();
			this.$log.info("submit called");
			if (!result) {
				this.$log.info("not valid");
				return;
			}

			this.loading = true;
			this.showErrorMessage = false;
			this.showSuccessMessage = false;
			try {
				const res = await Account.resetPassword(
					this.$route.query.Username,
					this.user.password,
					this.$route.query.Token
				);
				this.$log.info("Account.resetPassword: ", res);
				if (!res) {
					this.showSuccessMessage = true;
				} else {
					this.showErrorMessage = true;
					if (res.Message) {
						this.errorMessage = this.$t("resetError") + res.Message;
					} else {
						this.errorMessage = this.$t("resetErrorUnknown");
					}
				}
			} catch (Err) {
				this.$captureError(Err);
				this.showAuthError = true;
				this.authErrorMessage = Err.Message;
			} finally {
				this.loading = false;
			}
		},
		clear() {
			this.user.password_confirmation = "";
			this.user.password = "";
			this.$validator.reset();
		},
	},
};
</script>

<i18n>
{
	"it": {
		"pageTitle": "Reimposta Password",
		"titleDec": "Puoi reimpostare qui la tua password",
		"yourEmail": "La tua e-mail",
		"resetPassword": "Reimposta Password",
		"password": "Password",
		"password_confirmation": "Conferma password",
		"resetSuccess": "Password reimpostata con successo",
		"resetError": "Impossibile reimpostare la password ",
		"resetErrorUnknown": "Impossibile reimpostare la password (errore sconosciuto)"
	},
	"en": {
		"pageTitle": "Reset Password",
		"titleDec": "You can reset password here",
		"yourEmail": "Your e-mail",
		"btnRecoverPassword": "Recover Password",
		"resetSuccess": "Password is reseted successfully",
		"resetError": "Cannot reset password ",
		"resetErrorUnknown": "Cannot reset password (unknown error)"
	}
}
</i18n>
