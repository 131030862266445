<template>
	<div class="about">
		<vue-headful :title="$t('pageTitle')" :description="$t('pageTitle')" />
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2"
				>fas fa-caret-right</v-icon
			>
      </template>
		</v-breadcrumbs>

		<v-card class="elevation-5">
			<v-toolbar flat class="blue-grey lighten-4">
				<v-toolbar-title>Email Templates</v-toolbar-title>
			</v-toolbar>

			<confirm ref="confirm"></confirm>

			<v-card tile>
				<div style="height: 0px; position: relative">
					<v-btn absolute @click="OpenNewDialog" dark fab top right color="green">
						<v-icon>add</v-icon>
					</v-btn>
				</div>

				<skeleton-table v-if="LoadingData" :actions="2"></skeleton-table>
				<v-data-table
					v-show="!LoadingData"
					:items="items"
					hide-default-header
					:server-items-length="itemsPaging.TotalItems"
					:options.sync="options"
					@update:options="LoadTemplatesList"
					:footer-props="{
						itemsPerPageOptions: [10, 20, 50, 100],
					}"
				>
					<template v-slot:body="{ items }">
						<tbody>
							<tr v-for="item in items" :key="item.name">
								<td>
									<div
										class="ItemType secondary--text"
										v-text="item.EmailTemplateType.Description"
									></div>
									<div class="grey--text text--lighten-1">
										<span v-text="item.Subject"></span>
										<span
											class="grey--text text--darken-1 ml-3"
											v-text="item.UseMaster ? 'Master' : 'No Master'"
										></span>
									</div>
									<!-- <div class="grey--text text--lighten-1" v-text="item.UseMaster"></div> -->
								</td>
								<td class="layout justify-end">
									<v-btn icon ripple @click="OpenEditDialog(item.EmailTemplateId)">
										<v-icon color="primary">fas fa-fw fa-edit</v-icon>
									</v-btn>
									<v-btn icon ripple @click="OpenPreviewDialog(item)">
										<v-icon color="info">fas fa-fw fa-info-circle</v-icon>
									</v-btn>
									<v-btn icon ripple @click="DeleteEmailTemplate(item.EmailTemplateId)">
										<v-icon color="error">fas fa-fw fa-trash-alt</v-icon>
									</v-btn>
								</td>
							</tr>
						</tbody>
					</template>
				</v-data-table>
			</v-card>
		</v-card>

		<v-dialog
			v-model="dialogData.ShowEditDialog"
			persistent
			fullscreen
			hide-overlay
			no-click-animation
			@keydown.esc="CloseEditDialog"
		>
			<v-card tile class="email-templates-card">
				<v-toolbar dark color="primary">
					<v-btn icon dark @click="CloseEditDialog">
						<v-icon>close</v-icon>
					</v-btn>
					<v-toolbar-title>
						{{ dialogData.Title }}
						<v-progress-circular
							indeterminate
							v-if="this.dialogData.itemDetailsLoading"
							color="white"
						></v-progress-circular>
					</v-toolbar-title>
				</v-toolbar>
				<div>
					<v-card class="pa-4 elevation-3">
						<v-card-text>
							<form data-vv-scope="formAddStaticContent" v-if="metadataLoaded">
								<v-row>
									<v-col cols="12">
										<v-select
											:disabled="dialogData.Mode === 2"
											:items="filteredTemplateTypes"
											label="Template Type"
											item-text="Description"
											item-value="TemplateTypeId"
											v-model="dialogData.itemDetails.TemplateTypeId"
											data-vv-scope="formAddStaticContent"
											data-vv-name="TemplateTypeId"
											data-vv-delay="300"
											:counter="metadata['TemplateTypeId'].MaxLength"
											v-validate="metadata['TemplateTypeId'].ClientValidators"
											:error-messages="
												errors.collect('formAddStaticContent.TemplateTypeId')
											"
										></v-select>
									</v-col>
								</v-row>

								<v-row>
									<v-col cols="12">
										<v-text-field
											label="Subject"
											v-model="dialogData.itemDetails.Subject"
											data-vv-scope="formAddStaticContent"
											data-vv-name="Subject"
											data-vv-delay="300"
											:counter="metadata['Subject'].MaxLength"
											v-validate="metadata['Subject'].ClientValidators"
											:error-messages="errors.collect('formAddStaticContent.Subject')"
										></v-text-field>
									</v-col>
								</v-row>

								<v-row>
									<v-col cols="12">
										<v-checkbox
											label="UseMaster"
											v-model="dialogData.itemDetails.UseMaster"
											data-vv-scope="formAddStaticContent"
											data-vv-name="UseMaster"
											data-vv-delay="300"
											:counter="metadata['UseMaster'].MaxLength"
											v-validate="metadata['UseMaster'].ClientValidators"
											:error-messages="errors.collect('formAddStaticContent.UseMaster')"
										></v-checkbox>
									</v-col>
								</v-row>

								<v-row>
									<v-col cols="12">
										<v-textarea
											v-model="dialogData.itemDetails.HtmlTemplate"
											label="Html Template"
											data-vv-scope="formAddStaticContent"
											data-vv-name="HtmlTemplate"
											data-vv-delay="300"
											:counter="metadata['HtmlTemplate'].MaxLength"
											v-validate="metadata['HtmlTemplate'].ClientValidators"
											:error-messages="errors.collect('formAddStaticContent.HtmlTemplate')"
										/>
									</v-col>
								</v-row>
							</form>
						</v-card-text>
						<CardActionAddSave
							@addOrSaveClicked="AddOrSave"
							:mode="dialogData.Mode"
							:showCancelButton="false"
							:loading="dialogData.AddOrSaveLoading"
						></CardActionAddSave>
					</v-card>
				</div>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="ShowPreviewDialog"
			persistent
			fullscreen
			hide-overlay
			no-click-animation
			@keydown.esc="ClosePreviewDialog"
		>
			<v-card tile class="email-templates-card" v-if="previewItem">
				<v-toolbar dark color="primary">
					<v-btn icon dark @click="ClosePreviewDialog">
						<v-icon>close</v-icon>
					</v-btn>
					<v-toolbar-title>
						<div
							style="line-height: initial"
							class="title"
							v-text="previewItem.EmailTemplateType.Description"
						></div>
						<div
							style="line-height: initial"
							class="subtitle-1"
							v-text="previewItem.Subject"
						></div>

						<div
							style="line-height: initial"
							class="subtitle-1"
							v-text="previewItem.UseMaster"
						></div>
					</v-toolbar-title>
				</v-toolbar>
				<div>
					<iframe
						style="width: 100vw; height: calc(100vh - 71px)"
						:src="previewUrl"
						:key="previewId"
					></iframe>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import CardActionAddSave from "@/components/Shared/FormControl/CardActionAddSave";
import ConfirmTool from "@/components/Shared/Common/Confirm";
import metadata from "@/mixins/metadata";
import CrudClient from "@/services/CrudClient/";

export default {
	mixins: [metadata],
	components: {
		confirm: ConfirmTool,
		CardActionAddSave,
	},
	data() {
		return {
			EmailTemplatesService: new CrudClient("SystemAdmin/EmailTemplates"),
			LoadingData: false,
			items: [],
			itemsPaging: [], // TotalItems, Limit, Offset, CurrentPageIndex, TotalPages
			currentPage: 1,
			pageSize: 10,
			options: {
				page: 1,
				itemsPerPage: 20,
				totalItems: 0,
			},
			dialogData: {
				ShowEditDialog: false,
				Mode: 1, // 1 - New, 2 - Edit
				Title: "",
				activeTab: 0,
				EmailTemplateId: null,
				AddOrSaveLoading: false,
				itemDetails: {
					TemplateTypeId: null,
					HtmlTemplate: null,
					Subject: null,
					UseMaster: null,
				},
				itemDetailsLoading: false,
				TemplateTypes: [],
			},
			ShowPreviewDialog: false,
			previewItem: null,
			filteredTemplateTypes: [],
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],

			previewUrl: null,
		};
	},
	computed: {
		...mapGetters(["selectedApplicationId"]),
	},
	mounted: async function () {
		this.$log.info("Email Templates View Mounted");
		this.breadcrumbsItems.push(
			{
				text: this.$t("systemSettings"),
				disabled: false,
				exact: true,
				to: "/SystemSettings",
			},
			{
				text: this.$t("pageTitle"),
				disabled: true,
				exact: true,
				to: "/SystemSettings/EmailTemplates",
			}
		);
		await this.LoadTemplatesList();
		await this.LoadTemplateTypes();
		await this.LoadMetadata(this.EmailTemplatesService);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadTemplatesList() {
			this.LoadingData = true;
			try {
				const res = await this.EmailTemplatesService.GetPaged({
					limit: this.options.itemsPerPage,
					skip: (this.options.page - 1) * this.options.itemsPerPage,
					fields:
						"EmailTemplateId, EmailTemplateType.*, Subject, UseMaster, TemplateTypeId",
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
			} catch (error) {
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async OpenNewDialog() {
			this.dialogData.Mode = 1;
			this.dialogData.Title = "New Email Template";
			this.dialogData.ShowEditDialog = true;

			const createdIds = this.items.map((item) => item.TemplateTypeId);
			this.filteredTemplateTypes = this.dialogData.TemplateTypes.filter(
				(item) => !createdIds.includes(item.TemplateTypeId)
			);

			this.clearDialogForm();
		},

		async LoadTemplateTypes() {
			const res = await this.EmailTemplatesService.GetCustom("TemplateTypes");
			this.dialogData.TemplateTypes = res;
		},

		async OpenEditDialog(EmailTemplateId) {
			this.$log.info(EmailTemplateId);
			this.dialogData.Mode = 2;
			this.dialogData.Title = "Edit Email Template";
			this.dialogData.ShowEditDialog = true;
			this.dialogData.EmailTemplateId = EmailTemplateId;

			await this.LoadTemplateDetails();

			this.filteredTemplateTypes = [];
			const editTemplate = this.dialogData.TemplateTypes.find(
				(item) => item.TemplateTypeId === this.dialogData.itemDetails.TemplateTypeId
			);
			this.filteredTemplateTypes.push(editTemplate);
		},

		async OpenPreviewDialog(emailTemplate) {
			this.previewItem = emailTemplate;

			this.previewId = emailTemplate.EmailTemplateId;
			this.ShowPreviewDialog = true;

			this.previewUrl =
				`${process.env.VUE_APP_ROOT_API}SystemAdmin/EmailTemplates/${this.selectedApplicationId}/` +
				this.previewId +
				"/preview";
		},

		CloseEditDialog() {
			this.dialogData.ShowEditDialog = false;
		},

		ClosePreviewDialog() {
			this.ShowPreviewDialog = false;
		},

		clearDialogForm() {
			this.$log.info("Clear form data");
			Object.keys(this.dialogData.itemDetails).map((e) => {
				this.dialogData.itemDetails[e] = null;
			});
			this.$validator.reset();
		},

		async AddOrSave() {
			const result = await this.$validator.validateAll("formAddStaticContent");
			if (!result) return;
			this.dialogData.AddOrSaveLoading = true;
			try {
				const templateData = {};
				const CopyItems = [
					"Subject",
					"UseMaster",
					"TemplateTypeId",
					"HtmlTemplate",
				];
				CopyItems.map((v) => {
					templateData[v] = this.dialogData.itemDetails[v];
				});

				if (this.dialogData.Mode === 1) {
					const res = await this.EmailTemplatesService.Add(templateData);
					this.snackSuccess({ Text: this.$t("common.item.created") });
					await this.OpenEditDialog(res.EmailTemplateId); // no need to refetch data
				} else {
					const res = await this.EmailTemplatesService.Patch(
						this.dialogData.EmailTemplateId,
						templateData
					);
					this.snackSuccess({ Text: this.$t("common.item.updated") });
					await this.OpenEditDialog(res.EmailTemplateId); // no need to refetch data
				}
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdate") });
			} finally {
				this.dialogData.AddOrSaveLoading = false;
			}
			await this.LoadTemplatesList();
		},

		async LoadTemplateDetails() {
			try {
				this.dialogData.itemDetailsLoading = true;
				const res = await this.EmailTemplatesService.Get(
					this.dialogData.EmailTemplateId
				);
				const CopyItems = [
					"EmailTemplateId",
					"TemplateTypeId",
					"HtmlTemplate",
					"Subject",
					"UseMaster",
					"EmailTemplateType.Description",
				];
				CopyItems.map((v) => {
					this.dialogData.itemDetails[v] = res[v];
				});
			} finally {
				this.dialogData.itemDetailsLoading = false;
			}
		},

		async DeleteEmailTemplate(EmailTemplateId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete"),
					{ color: "error" }
				))
			) {
				return;
			}
			this.LoadingData = true;
			try {
				await this.EmailTemplatesService.Delete(EmailTemplateId);
				this.snackSuccess({ Text: this.$t("Template deleted successfully") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotDeleteContent") });
			} finally {
				this.LoadingData = false;
			}
			await this.LoadTemplatesList();
		},
	},
};
</script>

<style lang="scss" scoped>
.ItemType {
	font-size: 120%;
	font-weight: bold;
}
.email-templates-card {
	overflow-y: auto;
	max-height: calc(100vh - 64px);
}
</style>

<i18n>
{
    "it": {
		"pageTitle": "Email Templates",
		"systemSettings": "System Settings"
    }
}
</i18n>
