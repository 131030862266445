<template>
	<v-card v-bind="cardProps">
		<slot name="card-title" v-bind="cardTitleProps"></slot>
		<v-card-text>
			<slot name="card-beforeForm"></slot>
			<v-form
				v-bind:value="value"
				@input="$emit('input', $event)"
				:data-vv-scope="formContent.Scope"
				v-if="metadataLoaded"
				@keydown.enter.prevent="_submitForm"
			>
				<v-overlay :absolute="true" :value="formContent.FormLoading" :z-index="4">
					<v-progress-circular indeterminate size="64"></v-progress-circular>
				</v-overlay>
				<v-row v-for="field in formContentSchema" :key="field.key" dense>
					<v-col>
						<!-- TODO: exclude known standard fields from v-bind ie. listeners, type, key -->
						<component
							:is="field.type"
							:type="field['input-type']"
							v-bind="field"
							v-model="Values[field.key]"
							v-validate="field['v-validate']"
							:error-messages="errors.collect(formContent.Scope + '.' + field.key)"
							:hint="
								field['hint'] ||
								(field['v-validate'] && field['v-validate'].indexOf('required') >= 0
									? '* ' + $t('common.required')
									: null)
							"
							:persistent-hint="
								field['persistent-hint'] ||
								(!field['hint'] &&
									field['v-validate'] &&
									field['v-validate'].indexOf('required') >= 0)
							"
							v-on="field['listeners']"
						></component>
					</v-col>
				</v-row>
			</v-form>
			<slot name="card-afterForm"></slot>
		</v-card-text>
		<slot name="card-actions">
			<v-divider class="my-4" v-if="showDivider"></v-divider>
			<CardActionAddSave
				@addOrSaveClicked="_submitForm"
				@closeClicked="_closeClicked"
				:mode="mode"
				:showCancelButton="showCancelButton"
				:loading="formContent.FormSendingData"
				:addButtonProps="addButtonProps"
				:saveButtonProps="saveButtonProps"
				:closeButtonProps="closeButtonProps"
			>
				<template slot="btnAdd">
					<slot name="btnAdd"></slot>
				</template>
				<template slot="btnClose">
					<slot name="btnClose"></slot>
				</template>
				<template slot="btnSave">
					<slot name="btnSave"></slot>
				</template>
			</CardActionAddSave>
		</slot>
	</v-card>
</template>
<script>
import CardActionAddSave from "./CardActionAddSave";

// add all allowed controls
import DatePicker from "./DatePicker";
import InputRating from "./InputRating";
import ItalianCityPicker from "./ItalianCityPicker";
import ClientAccountPicker from "@/components/Account/ClientAccountPicker";
import ClientAccountWithTagsPicker from "@/components/Account/ClientAccountWithTagsPicker";

export default {
	$_veeValidate: { validator: "simpleFormCard" },
	components: {
		CardActionAddSave,
		DatePicker,
		InputRating,
		ClientAccountPicker,
		ItalianCityPicker,
		ClientAccountWithTagsPicker,
	},
	data() {
		return {
			Values: {},
		};
	},
	props: {
		cardProps: { type: Object },
		initialValues: { type: Object },
		formContent: { type: Object, required: true },
		metadata: { type: Object, required: true },
		metadataLoaded: { type: Boolean, required: true },
		mode: {
			type: Number,
			default: 2,
			validator: function (value) {
				return [1, 2].indexOf(value) !== -1;
			},
		},
		showCancelButton: { type: Boolean, default: true },
		showDivider: { type: Boolean, default: true },
		locali18n: { type: Object, required: true },
		addButtonProps: {
			type: Object,
		},
		saveButtonProps: {
			type: Object,
		},
		closeButtonProps: {
			type: Object,
		},
		cardTitleProps: {
			type: Object,
		},
		value: { type: Boolean },
	},
	computed: {
		formContentSchema: function () {
			const formScope = this.formContent.Scope;
			const schema = this.initializeFormDefaults(
				formScope,
				this.formContent.Fields
			);

			return schema;
		},
	},
	watch: {
		initialValues: {
			immediate: true,
			handler: function (newValues) {
				if (newValues) {
					this.SetValues(newValues);
				} else {
					this.ResetForm();
				}
			},
		},
	},
	methods: {
		async _submitForm() {
			this.$log.debug(this.formContent);

			if (!(await this.ValidateForm())) {
				return;
			}

			const data = this.CopyValues();

			this.$emit("submitForm", data);
		},

		_closeClicked() {
			this.$emit("closeClicked");
		},

		initializeFormDefaults(formScope, constFormFields) {
			const schema = {};

			constFormFields.forEach((field) => {
				const fieldName = typeof field === "string" ? field : field.FieldName;

				if (!fieldName) throw Error("FieldName cannot be null");

				// set standard fields
				schema[fieldName] = {
					key: fieldName,
					type: "v-text-field",
					label: this.locali18n.t(formScope + "." + fieldName), // this.$t(formScope + "." + fieldName),
					"data-vv-as": this.locali18n.t(formScope + "." + fieldName),
					"data-vv-scope": formScope,
					"data-vv-name": fieldName,
					"data-vv-delay": 300,
					counter: this.metadata[fieldName].MaxLength,
					"v-validate": this.metadata[fieldName].ClientValidators,
				};

				// apply custom fields
				if (typeof field !== "string") {
					/* eslint-disable-next-line no-unused-vars */
					const { FieldName: unusedVar, ...rest } = field;

					Object.keys(rest).forEach((key) => {
						if (typeof rest[key] === "string" && rest[key].startsWith("i18n:")) {
							rest[key] = this.locali18n.t(
								formScope + "." + rest[key].replace("i18n:", "")
							);
						}
					});

					schema[fieldName] = { ...schema[fieldName], ...rest };
				}
			});

			return schema;
		},

		ResetForm() {
			this._resetValues();
			this.$validator.reset();
		},

		CopyValues() {
			const newItem = {};
			this.formContent.Fields.map((field) => {
				const fieldName = typeof field === "string" ? field : field.FieldName;

				newItem[fieldName] = this.Values[fieldName];
			});
			return newItem;
		},

		_resetValues() {
			// set values reactive
			this.formContent.Fields.map((field) => {
				const fieldName = typeof field === "string" ? field : field.FieldName;

				this.$set(this.Values, fieldName, null);
			});
		},

		SetValues(data) {
			this.$log.info("Setting form Values");
			this.formContent.Fields.map((field) => {
				const fieldName = typeof field === "string" ? field : field.FieldName;

				this.$set(this.Values, fieldName, data[fieldName]);
			});
			this.$log.debug(this.Values);
		},

		async ValidateForm() {
			const validationResult = await this.$validator.validateAll(
				this.formContent.Scope
			);
			if (!validationResult) {
				this.$log.info(`form [${this.formContent.Scope}] not valid`);
				return false;
			}

			return true;
		},
	},
	mounted() {
		this.$log.info(`form [${this.formContent.Scope}] mounted `);
		if (!this.initialValues) this._resetValues();
	},
};
</script>
