const routes = [{
	path: "/Events",
	component: () => import(/* webpackChunkName: "EventsView" */ "./Index.vue")
},
{
	path: "/Offers",
	component: () => import(/* webpackChunkName: "EventsView" */ "./Offers.vue")
},
{
	path: "/Offers/:OfferId",
	component: () => import(/* webpackChunkName: "EventsView" */ "./OfferDetails.vue")
},
{
	path: "/Orders",
	component: () => import(/* webpackChunkName: "EventsView" */ "./Orders.vue")
},
{
	path: "/OrdersList",
	component: () => import(/* webpackChunkName: "EventsView" */ "./OrdersList.vue")
},
{
	path: "/Orders/:OrderId",
	component: () => import(/* webpackChunkName: "EventsView" */ "./OrderDetails.vue")
},

{
	path: "/Events/:EventId",
	component: () => import(/* webpackChunkName: "EventsView" */ "./EventDetails.vue")
},

{
	path: "/Events/:EventId/Offers",
	component: () => import(/* webpackChunkName: "EventsView" */ "./Offers.vue")
},
{
	path: "/Events/:EventId/Orders",
	component: () => import(/* webpackChunkName: "EventsView" */ "./Orders.vue")
},
{
	path: "/Direct",
	component: () => import(/* webpackChunkName: "EventsView" */ "./CreateDirectDeliveryOrder.vue")
}
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: ["Photographer", "BusinessAdmin"]
	};
}
);

export default routes;
