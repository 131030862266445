<template>
	<v-autocomplete
		v-bind="$attrs"
		v-bind:value="value"
		v-on="$listeners"
		@input="$emit('input', $event)"
		:loading="LoadingUsers"
		:label="$attrs.label || $t('sgp.client')"
		:items="clientAccounts"
		item-text="Text"
		item-value="Value"
		:search-input.sync="search"
		clearable
	>
		<template v-slot:item="data">
			<v-list-item-content>
				<v-list-item-title>
					{{ data.item.DisplayName }}
					<span v-if="data.item.Name"
						><v-icon small color="blue" class="ml-2">fas fa-tag</v-icon>
						{{ data.item.Name }}</span
					>
				</v-list-item-title>
			</v-list-item-content>
		</template>
	</v-autocomplete>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

export default {
	data() {
		return {
			LoadingUsers: false,
			clientAccounts: [],
			search: null,
			flagDoNotLoadUserList: false,
		};
	},
	props: {
		value: { type: String, default: null },
	},
	watch: {
		async search(val) {
			if (this.flagDoNotLoadUserList) {
				this.flagDoNotLoadUserList = false;
				return;
			}
			this.$log.debug("Watch search: " + val);

			val &&
				val !== this.value &&
				val.length > 0 &&
				(await this.LoadUserList(val));
		},

		value: {
			immediate: true,
			handler: async function (newVal) {
				this.$log.debug("watch value:" + newVal);

				if (!newVal) return;

				// Programmatic value set
				if (!this.clientAccounts.find((i) => i.UserId === newVal)) {
					const res = await this.UserManagementExtensionsService.Get(
						"ClientWithTag/" + newVal
					);

					this.flagDoNotLoadUserList = true;
					this.search = res.DisplayName + (res.Name ? " " + res.Name : "");
				}
			},
		},
	},
	created() {
		this.UserManagementExtensionsService = new CrudClient(
			"UserManagementExtensions/Users"
		);
	},
	async mounted() {
		await this.LoadUserList();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadUserList(val) {
			this.$log.debug("LoadUserList:" + val);
			try {
				if (!val) {
					this.clientAccounts = [];
				}

				this.LoadingUsers = true;
				const res = await this.UserManagementExtensionsService.Get(
					"SearchClientWithTag/" + encodeURI(val)
				);
				this.clientAccounts = res.map((r) => ({
					Text: r.DisplayName + (r.Name ? " " + r.Name : ""),
					Value: r.UserId + (r.SkillId ? "$" + r.SkillId : ""),
					DisplayName: r.DisplayName,
					Name: r.Name,
				}));
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingUsers = false;
			}
		},
	},
};
</script>
