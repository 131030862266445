import SystemAdminLogViewer from "./LogViewer.vue"
import SystemAdminIndex from "./Index.vue"
import SystemAdminEmailTemplates from "./EmailTemplates.vue"
import SystemAdminPhotographyLicenses from "./PhotographyLicenses.vue"
import FtpConnections from "./FtpConnections.vue"
import SynchronizationTasks from "./SynchronizationTasks.vue"
import ReportsPivotView from "./Reports/PivotView.vue"

const routes = [{
	path: "/SystemSettings",
	component: SystemAdminIndex
},
{
	path: "/SystemSettings/LogViewer",
	component: SystemAdminLogViewer
},
{
	path: "/SystemSettings/EmailTemplates",
	component: SystemAdminEmailTemplates
},
{
	path: "/SystemSettings/PhotographyLicenses",
	component: SystemAdminPhotographyLicenses
},
{
	path: "/SystemSettings/FtpConnections",
	component: FtpConnections
},
{
	path: "/SystemSettings/SynchronizationTasks",
	component: SynchronizationTasks
},
{
	path: "/SystemSettings/Reports/PivotView",
	component: ReportsPivotView
}
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: "SystemAdmin"
	};
}
);

export default routes;
