import accountService from "@/services/account"
import tokenStorage from "@/utilities/TokenStorage"
import moment from "moment"
import logger from "@/utilities/logger"
import CrudClient from "@/services/CrudClient/";
import router from "@/router"
import Vue from "vue"

import * as Sentry from "@sentry/browser";

export default {
	state: {
		profile: null,
		selectedApplicationId: "2B576049-1DBF-42B4-98A1-544BFF2A7464",
		isAuthenticated: false,
		authToken: null,
		tokenExpireTime: null,
		UserPublicProfiles: {},
		sasToken: null

	},
	mutations: {
		SET_PROFILE: (state, profile) => {
			state.profile = profile;
		},

		SET_SASTOKEN: (state, sasToken) => {
			state.sasToken = sasToken;
		},

		SET_AUTHTOKEN: (state, authToken) => {
			logger.$log.info("setting auth token");

			state.authToken = authToken;
			const expireTime = moment.utc().add(authToken.expires_in, "seconds");
			state.tokenExpireTime = expireTime;
		},

		SIGNOUT: (state) => {
			state.profile = null;
			state.isAuthenticated = false;
			state.authToken = null;
			state.tokenExpireTime = null;
		},

		IS_AUTHENTICATED: (state, isAuthenticated) => {
			state.isAuthenticated = isAuthenticated;
		},

		ADD_USEPUBLICRPROFILE: (state, profile) => {
			Vue.set(state.UserPublicProfiles, profile.Id, profile);
		},
	},
	actions: {

		SetRefreshToken(context, token) {
			context.commit("SET_AUTHTOKEN", {
				refresh_token: token
			});
		},

		async GetSasToken(context) {
			const service = new CrudClient("StorageSasTokenController");

			const sasToken = await service.Get(null);
			context.commit("SET_SASTOKEN", sasToken);
		},

		async LoadUserData(context) {
			logger.$log.debug("Start: LoadUserData" + !!context.getters.getAuthToken);

			if (!context.getters.getAuthToken) {
				Sentry.configureScope((scope) => {
					scope.setUser();
				});
				return;
			}

			let data;
			try {
				data = await accountService.loadUserDetails();

				logger.$log.debug(data);
			} catch (err) {
				logger.$log.error("Cannot load user details");
				logger.$log.error(err);
				return;
			}

			context.commit("SET_PROFILE", data);

			context.commit("IS_AUTHENTICATED", true);

			await context.dispatch("GetSasToken");

			logger.$log.debug("Sentry.configureScope");

			Sentry.configureScope(function(scope) {
				scope.setUser({
					id: data.AspNetUser.Id,
					username: data.AspNetUser.UserName
				});
			});
		},

		async Signin(context, {
			username,
			password,
			rememberMe,
			applicationId
		}) {
			const authResult = await accountService.signup(username, password);

			if (!authResult.error) {
				await context.dispatch("SignOut");

				tokenStorage.persistAuthResult(authResult, rememberMe);
				context.commit("SET_AUTHTOKEN", authResult);

				// it's not possible to call an action within an action directly
				// await this.LoadUserData();
				await context.dispatch("LoadUserData", applicationId);
			}

			return authResult;
		},

		// handle redirect where it is called
		async RefreshAccessToken(context, loadTokenFromStorage) {
			if (loadTokenFromStorage) {
				logger.$log.info("RefreshAccessToken loadTokenFromStorage");

				// get token from storage to store
				const token = tokenStorage.getAuthToken();

				if (token) {
					context.commit("SET_AUTHTOKEN", token);
				} else { return false; }
			}

			// if there is no token available do not ask for a refresh token
			if (!context.getters.getAuthToken) {
				return false;
			}
			const authResult = await accountService.refreshAccessToken(context.getters.getAuthToken.refresh_token);

			if (authResult.error) {
				await context.dispatch("SignOut");
				return false;
			} else {
				tokenStorage.persistAuthResult(authResult);
				context.commit("SET_AUTHTOKEN", authResult);
				return true;
			}
		},

		async refreshTokenFromInstantLoginToken(context, instantLoginToken) {
			try {
				const token = await accountService.refreshTokenFromInstantLoginToken(instantLoginToken);
				// it's not possible to call an action within an action directly
				await context.dispatch("SetRefreshToken", token.refresh_token);
				await context.dispatch("RefreshAccessToken", false);
			} catch (error) {
				logger.$log.error(error);

				router.push("/Login?InvalidAccessToken=true")
			}
		},

		async SignOut(context) {
			try {
				if (context.getters.getAuthToken) { await accountService.revokeAccessToken(context.getters.getAuthToken.refresh_token); }
			} catch (error) {
				logger.$log.error(error);
			}

			context.commit("SIGNOUT");
			tokenStorage.clearAuthToken();

			// if the chat module is available
			if (context.rootState.chat) { await context.dispatch("ShutdownChatClient"); }
		},

		async LoadUserPublicProfile(context, id) {
			try {
				if (context.state.UserPublicProfiles[id]) { return context.state.UserPublicProfiles[id]; }

				const PublicProfileService = new CrudClient("UserDetails");

				const publicProfile = await PublicProfileService.GetCustom(
					`PublicProfile/${id}`,
					false
				);

				context.commit("ADD_USEPUBLICRPROFILE", publicProfile);
			} catch (err) {
				logger.$log.error(err);
			}
		}

	},
	getters: {
		UserPublicProfiles: (state) => {
			return state.UserPublicProfiles;
		},

		SasToken: (state) => {
			return state.sasToken;
		},

		selectedApplicationId: state => {
			return state.selectedApplicationId;
		},

		// SelectedApplication: state => {
		// 	return null;
		// },

		UserProfile: state => {
			return state.profile;
		},

		isUserInRole: (state) => (role) => {
			if (!state.profile) { return false; }

			const appRoles = state.profile.AspNetUser.ApplicationUsers.find(au => au.ApplicationId.toLowerCase() === state.selectedApplicationId.toLowerCase());

			return !!appRoles
				.ApplicationUserRoles
				.find(r => r.AspNetRole.Name === role);
		},

		isUserInRoles: (state) => (roles) => {
			if (!state.profile) { return false; }
			const appRoles = state.profile.AspNetUser.ApplicationUsers.find(au => au.ApplicationId.toLowerCase() === state.selectedApplicationId.toLowerCase());

			for (let i = 0; i < roles.length; i++) {
				const role = appRoles
					.ApplicationUserRoles
					.find(r => r.AspNetRole.Name === roles[i]);

				if (role) {
					return true;
				}
			}

			return false;
		},

		isUserAuthenticated: (state) => {
			return state.isAuthenticated;
		},

		getAuthToken: (state) => {
			return state.authToken;
		},

		getAuthHeader: (state) => {
			if (state.authToken) {
				return {
					Authorization: "bearer " + state.authToken.access_token
				};
			} else { return {}; }
		},

		isAccessTokenValid: (state) => {
			const now = moment.utc();
			return now.isBefore(state.tokenExpireTime);
		}

	}
}
