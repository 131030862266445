import store from "@/store";

export function getParameterByName(name, url) {
	if (!url) url = window.location.href;
	// eslint-disable-next-line no-useless-escape
	name = name.replace(/[\[\]]/g, "\\$&");
	var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
	var results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return "";
	return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function routerGuardsSetup(router) {
	router.beforeEach((to, _from, next) => {
		const applicationId = getParameterByName("ApplicationId");
		const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
		const currentPath = window.location.pathname + window.location.search;
		if (!requiresAuth) {
			next();
			return;
		}
		const allowedRoles = to.meta.allowedRoles;
		if (!store.getters.isUserAuthenticated) {
			next({
				name: "login",
				query: {
					ReturnUrl: currentPath,
					reason: "NotAuthenticated",
					ApplicationId: applicationId
				}
			});
		} else if (allowedRoles) {
			// roles array
			if (Array.isArray(allowedRoles)) {
				for (let i = 0; i < allowedRoles.length; i++) {
					if (store.getters.isUserInRole(allowedRoles[i])) {
						next();
						return;
					}
				}
				// not found
				next({
					name: "login",
					query: {
						ReturnUrl: currentPath,
						reason: "NotInAnyRole",
						ApplicationId: applicationId
					}
				});
			} else { // single role
				if (!store.getters.isUserInRole(allowedRoles)) {
					next({
						name: "login",
						query: {
							ReturnUrl: currentPath,
							reason: "NotInRole",
							ApplicationId: applicationId
						}
					});
				} else { next(); }
			}
		} else {
			next();
		}
	});
}
