const routes = [{
	path: "/Clients",
	component: () => import(/* webpackChunkName: "ClientArea" */ "./Index.vue")
},
{
	path: "/Clients/Offers",
	component: () => import(/* webpackChunkName: "ClientArea" */ "./Offers.vue")
},
{
	path: "/Clients/Offers/:offerId",
	component: () => import(/* webpackChunkName: "ClientArea" */ "./OfferDetails.vue")
},
{
	path: "/Clients/Orders",
	component: () => import(/* webpackChunkName: "ClientArea" */ "./Orders.vue")
},
{
	path: "/Clients/Orders/:orderId",
	component: () => import(/* webpackChunkName: "ClientArea" */ "./OrderDetails.vue")
},
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: ["Client", "ClientSubAccount"]
	};
}
);

export default routes;
