import Vue from "vue"
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"

import it from "vuetify/es5/locale/it"

import "material-design-icons-iconfont/dist/material-design-icons.css"
// Helpers
import colors from "vuetify/es5/util/colors"

Vue.use(Vuetify)

const opts = {
	lang: {
		locales: {
			it
		},
		current: "it"
	},
	icons: {
		iconfont: "fa"
	},
	theme: {
		dark: false,
		themes: {
			// Note: when changed, sync with variables.scss
			light: {
				success: colors.green.base
			}
		}
	}
}

export default new Vuetify(opts)
