<template>
	<v-app id="app">
		<custom-snack-bar
			ref="snackBar"
			@snackBarClosed="snackBarClosed"
		></custom-snack-bar>
		<sidebar-menu ref="sidebar" initialDrawerOpen="true"></sidebar-menu>

		<v-app-bar app hide-on-scroll>
			<v-app-bar-nav-icon icon @click="ToggleSidebar"></v-app-bar-nav-icon>
			<v-toolbar-title>
				<router-link to="/">
					<v-img
						src="@/assets/img/logoSgp.png"
						contain
						height="70"
						width="100"
					></v-img
				></router-link>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on"
							><v-icon left>fas fa-globe</v-icon> {{ $i18n.locale }}</v-btn
						>
					</template>
					<v-list>
						<v-list-item @click="ChangeLanguage('it')">
							<v-list-item-title>Italiano</v-list-item-title>
						</v-list-item>
						<v-list-item @click="ChangeLanguage('en')">
							<v-list-item-title>English</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-toolbar-items>
		</v-app-bar>

		<!-- Sizes your content based upon application components -->
		<v-main>
			<!-- Provides the application the proper gutter -->
			<v-container fluid class="mb-5" style="min-height: 500px">
				<!-- If using vue-router -->
				<router-view :key="$route.fullPath"></router-view>
			</v-container>

			<v-footer color="grey darken-3" dark>
				<v-row>
					<v-col cols="12" class="pa-3 text-center text-body-2">
						SGP Srl - via Sansovino, 8 - 20133 Milano (MI) - Italy - REA MI
						1682239 - C.F./P.I./Reg.Imp. MI 03540510967 - Cap. Soc. € 30.000
						i.v.
						<br />
						<div class="text-body-1">
							<router-link class="blue--text text--lighten-3 mr-5" to="/Privacy"
								>Informativa sulla privacy</router-link
							>

							<router-link
								class="blue--text text--lighten-3"
								to="/Terms-Conditions"
								>Termini e condizioni</router-link
							>
						</div>
					</v-col>
				</v-row>
			</v-footer>
		</v-main>
	</v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CustomSnackBar from "./components/Shared/Common/CustomSnackBar.vue";
import SidebarMenu from "./components/Layout/SidebarMenu.vue";

export default {
	components: {
		"custom-snack-bar": CustomSnackBar,
		SidebarMenu
	},
	methods: {
		...mapActions(["snackResetNotification"]),

		ToggleSidebar() {
			this.$refs.sidebar.ToggleDrawer();
		},

		CloseSidebar() {
			this.$refs.sidebar.showDrawer = false;
		},

		snackBarClosed() {
			this.snackResetNotification();
		},
		ChangeLanguage(locale) {
			this.$log.debug("change:" + locale);
			// this.SetCurrentLanugage(locale);

			if (this.$root.$i18n.locale !== locale) {
				this.$root.$i18n.locale = locale;
				localStorage.setItem("locale", this.$root.$i18n.locale);
			}
		}
	},
	data() {
		return {
			VUE_APP_RUN_NUMBER: process.env.VUE_APP_RUN_NUMBER
		};
	},
	computed: {
		...mapGetters([
			"UserProfile",
			"selectedApplicationId",
			"isUserAuthenticated",
			"isUserInRoles"
		])
	},
	mounted() {
		// this.$log.debug(
		// 	"Who am I: " +
		// 		(this.isUserAuthenticated ? this.UserProfile.Id : "not signed in")
		// );

		const savedLocale = localStorage.getItem("locale");
		this.$log.debug("saved locale" + savedLocale);
		if (savedLocale) {
			this.ChangeLanguage(savedLocale);
		}

		this.$store.watch(
			state => state.notifications.snack,
			newValue => {
				if (newValue.Text !== "") {
					this.$refs.snackBar.showNotification({
						Text: this.$store.state.notifications.snack.Text,
						Color: this.$store.state.notifications.snack.Color,
						Header: this.$store.state.notifications.snack.Header,
						Icon: this.$store.state.notifications.snack.Icon
					});
				}
			}
		);
	}
};
</script>

<style lang="scss">
@import "@/assets/css/AppStyle.scss";
@import "fontawesome-pro/css/all.css";

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.3s;
	transition-property: opacity;
	transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
	opacity: 0;
}
</style>
<i18n>
{
	"it":{
		"test": "value"
	}
}
</i18n>
