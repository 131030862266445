<template>
	<div class="pa-1">
		<v-avatar :size="size" color="purple" :tile="false">
			<v-img
				v-if="profile.ImageFilePath"
				:src="profile.ImageFilePath['100']"
			></v-img>
			<span class="avatarText" :style="{ fontSize: size * 0.5 + 'px' }" v-else>{{
				profile.DisplayName | getInitial
			}}</span>
		</v-avatar>
		<span v-if="showDisplayName" :class="textClass">{{
			profile.DisplayName
		}}</span>
	</div>
</template>
<script>
export default {
	name: "UserAvatarFullname",
	props: {
		profile: Object,
		size: {
			type: Number,
			default: 36,
		},
		textClass: {
			type: String,
			default: "subheading ml-2",
		},
		showDisplayName: {
			type: Boolean,
			default: true,
		},
	},
};
</script>
