<template>
	<div>
		<div tile primary-title class="secondary white--text px-6 py-2">
			<div class="headline">{{ $t("pageTitle") }}</div>
			<div class="subtitle-1">{{ $t("loginToContinue") }}</div>
		</div>
		<!-- <div class="pa-5">
			<v-form @submit.prevent="submit" class="pa-5" v-model="isFormValid">
				<v-btn
					class="mb-3"
					color="primary"
					type="submit"
					:loading="loading"
					block
					large
					>{{ $t("common.login") }}</v-btn
				>
				<v-btn
					to="/RecoverPassword"
					class="text-wrap text-transform-none pa-0"
					color="primary"
					block
					large
					text
					>{{ $t("forgetPassword") }}</v-btn
				>

			</v-form>
		</div>
		<v-overlay :value="isLoadingUserData">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay> -->

		<simple-form-card
			:metadata="metadata"
			:metadataLoaded="metadataLoaded"
			ref="formLogin"
			:formContent="formLogin"
			:showCancelButton="false"
			:locali18n="$i18n"
			:saveButtonProps="{ color: 'primary', block: true }"
			@submitForm="LoginUser"
			:showDivider="false"
		>
			<template v-slot:card-afterForm>
				<v-alert :value="true" v-if="showAuthError" type="error">{{
					authErrorMessage
				}}</v-alert>
				<v-btn
					to="/RecoverPassword"
					class="text-wrap text-transform-none pa-0"
					color="primary"
					block
					text
					>{{ $t("forgetPassword") }}</v-btn
				>
			</template>
			<template v-slot:btnSave>{{ $t("common.login") }}</template>
		</simple-form-card>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SimpleFormCard from "./Shared/FormControl/SimpleFormCard";
import CrudFormData from "@/utilities/CrudFormData.js";

export default {
	components: { SimpleFormCard },
	name: "LoginForm",
	$_veeValidate: { validator: "new" },
	props: {
		ReturnUrl: {
			type: String,
			default: "/",
		},
		NoRedirect: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showPassword: false,
			showAuthError: false,
			authErrorMessage: "",
			metadataLoaded: true,
			metadata: {
				UserName: {
					ClientValidators: "required",
					MaxLength: null,
				},
				Password: {
					ClientValidators: "required",
					MaxLength: null,
				},
				RememberMe: {
					ClientValidators: "",
					MaxLength: null,
				},
			},
			formLogin: new CrudFormData("formLogin", [
				{
					"prepend-icon": "fas fa-user",
					FieldName: "UserName",
				},
				{
					type: "v-text-field",
					"prepend-icon": "lock",
					"input-type": "password",
					FieldName: "Password",
					"append-icon": "fas fa-eye",
					listeners: {
						"click:append": this.changeVisibility,
					},
				},
				{
					type: "v-checkbox",
					FieldName: "RememberMe",
				},
			]),
		};
	},
	computed: {
		...mapGetters(["selectedApplicationId"]),
	},
	methods: {
		...mapActions(["Signin"]),

		changeVisibility() {
			this.showPassword = !this.showPassword;

			const field = this.formLogin.Fields.find((f) => f.FieldName === "Password");

			field["input-type"] = this.showPassword ? "text" : "password";
			field["append-icon"] = this.showPassword ? "fas fa-eye-slash" : "fas fa-eye";
		},

		async LoginUser(dto) {
			this.$log.info("submit called");
			this.formLogin.FormLoading = true;
			try {
				const res = await this.$validator.validateAll();
				if (res) {
					this.showAuthError = false;
					const authRes = await this.Signin({
						username: dto.UserName,
						password: dto.Password,
						rememberMe: dto.RememberMe,
					});
					if (authRes.error) {
						this.showAuthError = true;
						this.authErrorMessage = this.$t("loginResponse_InvalidUserNamePassword");
					} else {
						this.onLoginSuccessful();
					}
				}
			} catch (err) {
				this.$captureError(err);
				this.showAuthError = true;
				this.authErrorMessage = this.$t("loginResponse_OtherError");
			} finally {
				this.formLogin.FormLoading = false;
			}
		},

		onLoginSuccessful() {
			this.$emit("loginSuccessful");

			// redirect if OK
			if (!this.NoRedirect) {
				setTimeout(() => {
					this.$router.push(this.ReturnUrl);
				}, 200);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
</style>

<i18n>
{
	"it":
	{
		"pageTitle": "Accedi al tuo account",
		"loginToContinue": "Effettuare il login per continuare",
		"yourUsernameValidation": "nome utente o indirizzo email",
		"yourPasswordValidation": "password",
		"forgetPassword": "Hai dimenticato i tuoi dati di accesso?",
		"loginResponse_InvalidUserNamePassword": "Nome utente o password non valido!",
		"loginResponse_OtherError": "Errore: Possibile problema di connessione al server",
		"snackCannotLogin": "Impossibile effettuare il login",

		"formLogin":{
			"UserName": "Nome utente o indirizzo email",
			"Password": "Password",
			"RememberMe":"Ricordami su questo computer"
		}
	}
}

</i18n>
