<template>
	<v-menu v-model="showDateMenu" :close-on-content-click="false" max-width="290">
		<template v-slot:activator="{ on }" v-on="$listeners">
			<v-text-field
				v-on="on"
				:prepend-icon="prependIcon"
				readonly
				v-bind="$attrs"
				v-bind:value="value | formatDate"
				clearable
				@click:clear="onClearClicked"
			></v-text-field>
		</template>
		<v-date-picker
			v-bind:value="selectedDate"
			locale="it-IT"
			@input="OnDateChanged"
			v-bind="datePickerProps"
		></v-date-picker>
	</v-menu>
</template>

<script>
import moment from "moment";

export default {
	inheritAttrs: false,
	props: {
		value: {
			type: String,
		},
		datePickerProps: { type: Object },
	},
	data() {
		return {
			showDateMenu: false,
			prependIcon: "fas fa-calendar-alt",
		};
	},
	computed: {
		selectedDate() {
			if (this.value) {
				return moment(this.value).format("YYYY-MM-DD");
			} else {
				return null;
			}
		},
	},
	methods: {
		onClearClicked() {
			this.$emit("input", null);
		},
		OnDateChanged(value) {
			this.showDateMenu = false;
			this.$log.debug("emit:" + value);
			this.$emit("input", value);
		},
	},
};
</script>
