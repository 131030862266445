<template>
	<div>
		<vue-headful :title="$t('pageTitle')" :description="$t('pageTitle')" />
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2"
				>fas fa-caret-right</v-icon
			>
      </template>
		</v-breadcrumbs>

		<v-card class="elevate-5">
			<v-toolbar flat class="blue-grey lighten-4">
				<v-toolbar-title>{{ $t("pageTitle") }}</v-toolbar-title>
			</v-toolbar>

			<v-row class="mx-1 py-3">
				<v-col
					cols="12"
					sm="6"
					md="4"
					class="py-1 static-navigation"
					v-for="item in staticNavigation"
					:key="item.text"
				>
					<v-card
						dark
						color="secondary"
						class="pa-1 elevateOnHover"
						:to="{ path: item.path }"
						append
					>
						<v-card-text>
							<v-icon class="mr-2" v-if="item.icon">{{ item.icon }}</v-icon>
							<span class="ItemText white--text">{{ item.text }}</span>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
export default {
	data() {
		return {
			LoadingData: false,
			staticNavigation: [
				{
					path: "LogViewer",
					text: "Log Viewer",
					icon: "fas fa-fw fa-heart-rate",
				},

				{
					path: "EmailTemplates",
					text: "Email Templates",
					icon: "fas fa-fw fa-file-user",
				},
				{
					path: "PhotographyLicenses",
					text: this.$t("sgp.license"),
					icon: "fas fa-fw fa-file-certificate",
				},
				{
					path: "FtpConnections",
					text: this.$t("sgp.ftpConnections"),
					icon: "fas fa-fw fa-server",
				},
				{
					path: "SynchronizationTasks",
					text: this.$t("sgp.synchronizationTasks"),
					icon: "fas fa-fw fa-cloud-download",
				},
				{
					path: "Reports/PivotView",
					text: this.$t("sgp.reports"),
					icon: "fas fa-fw fa-chart-line",
				},
			],
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
		};
	},
	mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/SystemSettings",
		});
	},
};
</script>

<style lang="scss">
a {
	text-decoration: none;
}
.ItemText {
	font-size: 120%;
}
</style>

<i18n>
{
    "it": {
		"pageTitle": "System Settings"
    }
}
</i18n>
