<template>
	<div>
		<confirm ref="confirm"></confirm>
		<vue-headful :title="$t('sgp.license')" :description="$t('pageMetaDescription')" />
		<v-breadcrumbs :items="breadcrumbsItems" class="pl-3">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
      </template>
    </v-breadcrumbs>

		<div class="elevation-5">
			<v-toolbar flat color="blue-grey lighten-4">
				<v-toolbar-title>{{ $t("sgp.license") }}</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar>
			<div style="height: 0px; position: relative">
				<v-btn absolute @click="ShowNewDialog" dark fab top right color="green">
					<v-icon>add</v-icon>
				</v-btn>
			</div>

			<div class="pa-5">
				<AddEditPhotographyLicenseDialog v-model="showDetailsDialog" :mode="dialogMode" @updated="LoadLicenses"
					:LicenseId="editLicenseId"></AddEditPhotographyLicenseDialog>

				<v-progress-linear :indeterminate="true" v-show="LoadingData"></v-progress-linear>
				<draggable v-model="items" @end="DragEnd" @start="DragStart" handle=".dragElement"
					v-bind="{ animation: 450 }" tag="div" class="layout row wrap">
					<v-col cols="12" sm="6" lg="3" v-for="item in items" v-bind:key="item.LicenseId">
						<v-card class="mb-4" :color="item.IsActive ? '' : 'grey'">

							<v-card-text class="text--primary">
								<h5>{{ item.Name }}</h5>
								<span class="font-weight-bold pr-1">
									{{ $t("common.description") }}:</span>
								{{ item.Description }}

								<v-divider class="my-3"></v-divider>
								<v-subheader>English</v-subheader>
								<h5>{{ item.NameEN }}</h5>
								<span class="font-weight-bold pr-1">
									{{ $t("common.description") }}:</span>
								{{ item.DescriptionEN }}

								<v-divider class="my-3"></v-divider>

								<div>
									<span class="font-weight-bold pr-1"> Luogo:</span>
									<span v-if="item.PhotographyLicenseArea">{{ item.PhotographyLicenseArea.Name |
										emptyField }}
									</span>
									<span v-else>-</span>
								</div>
								<div>
									<span class="font-weight-bold pr-1"> Tipo contenuto:</span>
									<span v-if="item.MediaType">{{ item.MediaType.TypeName | emptyField }}
									</span>
									<span v-else>-</span>
								</div>
								<div>
									<span class="font-weight-bold pr-1"> Durata:</span>
									<span v-if="item.ExpiresInMonths">{{ item.ExpiresInMonths }} mesi</span>
									<span v-else>lifetime</span>
								</div>
								<div>
									<span class="font-weight-bold pr-1"> Prezzo:</span>
									{{ item.DefaultPrice | formatCurrency }}
								</div>
							</v-card-text>
							<v-card-actions>
								<v-btn icon class="dragElement"><v-icon>fas fa-arrows</v-icon></v-btn>
								<v-spacer></v-spacer>
								<v-chip color="grey--darken-5" dark v-if="!item.IsActive">{{
									$t("common.isItemActive.false")
									}}</v-chip>
								<v-btn icon @click="DeleteLicense(item.LicenseId)"><v-icon>fas fa-trash</v-icon></v-btn>
								<v-btn icon @click="OpenEditLiceseDialog(item.LicenseId)"><v-icon>fas
										fa-edit</v-icon></v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
				</draggable>
			</div>
		</div>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import AddEditPhotographyLicenseDialog from "@/components/PhotographyLicenses/AddEditLicenseDialog.vue";
import Confirm from "@/components/Shared/Common/Confirm";
import draggable from "vuedraggable";
import Helpers from "@/utilities/Helpers/";

import { mapActions } from "vuex";

export default {
	components: {
		AddEditPhotographyLicenseDialog,
		Confirm,
		draggable,
	},

	data() {
		return {
			showDetailsDialog: false,
			dialogMode: 1,
			items: [],
			breadcrumbsItems: [],
			LoadingData: false,
			editLicenseId: null,
			DragItemsList: [],
		};
	},
	watch: {
		// when dialog is closed reset LicenseId so we can reload values when re-open
		showDetailsDialog: async function (val) {
			this.$log.debug("showDetailsDialog:" + val);
			if (!val) {
				this.editLicenseId = null;
			}
		},
	},
	created() {
		this.PhotographyLicensesService = new CrudClient("SGP/PhotographyLicenses");
	},
	async mounted() {
		this.breadcrumbsItems.push(
			{
				text: this.$t("systemSettings"),
				disabled: false,
				exact: true,
				to: "/SystemSettings",
			},
			{
				text: this.$t("sgp.license"),
				disabled: true,
				exact: true,
				to: "/SystemSettings/PhotographyLicenses",
			}
		);
		await this.LoadLicenses();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		ShowNewDialog() {
			this.editLicenseId = null;
			this.dialogMode = 1;
			this.showDetailsDialog = true;
		},

		async OpenEditLiceseDialog(LicenseId) {
			this.editLicenseId = LicenseId;
			this.dialogMode = 2;
			this.showDetailsDialog = true;
		},

		async LoadLicenses() {
			try {
				this.LoadingData = true;

				const res = await this.PhotographyLicensesService.GetPaged({
					skip: 0,
					limit: 0,
					fields: "*, PhotographyLicenseArea.*, MediaType.*",
				});

				this.items = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},

		async DeleteLicense(LicenseId) {
			this.$log.debug(LicenseId);
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete"),
					{ color: "error" }
				))
			) {
				return;
			}
			this.LoadingData = true;
			try {
				await this.PhotographyLicensesService.Delete(LicenseId);
				this.snackSuccess({ Text: this.$t("success.itemDelete") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotDeleteItem") });
			} finally {
				this.LoadingData = false;
			}
			await this.LoadLicenses();
		},

		DragStart() {
			this.DragItemsList = this.items.map((item) => item.LicenseId);
		},

		async DragEnd() {
			// BUG: resizing with paging doesn't work as expected
			this.$log.info("Drag End");
			const itemsArray = this.items.map((item) => item.LicenseId);
			// compare to initial state
			if (Helpers.compareArrays(this.DragItemsList, itemsArray)) {
				return;
			}
			try {
				this.LoadingData = true;
				await this.PhotographyLicensesService.Reorder(itemsArray);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadDetails") });
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>
<i18n>
{
	"it":
	{
		"systemSettings": "System Settings"
	}
}
</i18n>
