const ShopAdminIndex = () => import(/* webpackChunkName: "ShopAdmin" */ "./index");
const EditChapters = () => import(/* webpackChunkName: "ShopAdmin" */ "./Chapters");
const ChapterFiles = () => import(/* webpackChunkName: "ShopAdmin" */ "./ChapterFiles");
const orders = () => import(/* webpackChunkName: "ShopAdmin" */ "./Orders");
const orderDetails = () => import(/* webpackChunkName: "ShopAdmin" */ "./OrderDetails");
const ItemVersions = () => import(/* webpackChunkName: "ShopAdmin" */ "./ItemVersions");
const ShopProjectHomeSelectedItems = () => import(/* webpackChunkName: "ShopAdmin" */ "./ShopProjectHomeSelectedItems");
const routes = [{
	path: "/Shop",
	component: ShopAdminIndex
},
{
	path: "/Shop/Chapters",
	component: EditChapters
},
{
	path: "/Shop/Chapter/:ChapterId",
	component: ChapterFiles
},
{
	path: "/Shop/Chapter/:ChapterId/:ItemId/Versions",
	component: ItemVersions
},
{
	path: "/Shop/Orders",
	component: orders
},
{
	path: "/Shop/Orders/:OrderId",
	component: orderDetails
},
{
	path: "/Shop/HomeSelectedItems",
	component: ShopProjectHomeSelectedItems
}
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: "BusinessAdmin"
	};
}
);

export default routes;
