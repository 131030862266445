<template>
	<div>
		<confirm ref="confirm"></confirm>
		<vue-headful
			:title="$t('sgp.ftpConnections')"
			:description="$t('pageMetaDescription')"
		/>
		<v-breadcrumbs :items="breadcrumbsItems" class="pl-3">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
          >fas fa-caret-right</v-icon
        >
      </template>
		</v-breadcrumbs>

		<div class="elevation-5">
			<v-toolbar flat color="blue-grey lighten-4">
				<v-toolbar-title>{{ $t("sgp.ftpConnections") }}</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar>
			<div style="height: 0px; position: relative">
				<v-btn absolute @click="ShowNewDialog" dark fab top right color="green">
					<v-icon>add</v-icon>
				</v-btn>
			</div>

			<div class="pa-5">
				<AddEditFtpConnectionDialog
					v-model="showDetailsDialog"
					:mode="dialogMode"
					@updated="LoadFtpConnections"
					:ConnectionId="editItemId"
				></AddEditFtpConnectionDialog>

				<v-progress-linear
					:indeterminate="true"
					v-show="LoadingData"
				></v-progress-linear>
				<v-row>
					<v-col
						cols="12"
						sm="6"
						lg="3"
						v-for="item in items"
						v-bind:key="item.ConnectionId"
					>
						<v-card class="mb-4">
							<v-card-title>{{ item.Name }}</v-card-title>
							<v-card-text class="text--primary">
								<span class="font-weight-bold pr-1">Url:</span> {{ item.Url }}
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>

								<v-btn icon @click="DeleteLicense(item.ConnectionId)"
									><v-icon>fas fa-trash</v-icon></v-btn
								>
								<v-btn icon @click="OpenEditFtpConnectionDialog(item.ConnectionId)"
									><v-icon>fas fa-edit</v-icon></v-btn
								>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</div>
		</div>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import AddEditFtpConnectionDialog from "@/components/FtpConnection/AddEditFtpConnectionDialog.vue";
import Confirm from "@/components/Shared/Common/Confirm";

import { mapActions } from "vuex";

export default {
	components: {
		AddEditFtpConnectionDialog,
		Confirm,
	},

	data() {
		return {
			showDetailsDialog: false,
			dialogMode: 1,
			items: [],
			breadcrumbsItems: [],
			LoadingData: false,
			editItemId: null,
		};
	},
	watch: {
		// when dialog is closed reset id so we can reload values when re-open
		showDetailsDialog: async function (val) {
			this.$log.debug("showDetailsDialog:" + val);
			if (!val) {
				this.editItemId = null;
			}
		},
	},
	created() {
		this.FtpConnectionsService = new CrudClient("SGP/FTP/Connections");
	},
	async mounted() {
		this.breadcrumbsItems.push(
			{
				text: this.$t("systemSettings"),
				disabled: false,
				exact: true,
				to: "/SystemSettings",
			},
			{
				text: this.$t("sgp.ftpConnections"),
				disabled: true,
				exact: true,
				to: "/SystemSettings/FtpConnections",
			}
		);
		await this.LoadFtpConnections();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		ShowNewDialog() {
			this.editItemId = null;
			this.dialogMode = 1;
			this.showDetailsDialog = true;
		},

		async OpenEditFtpConnectionDialog(connectionId) {
			this.editItemId = connectionId;
			this.dialogMode = 2;
			this.showDetailsDialog = true;
		},

		async LoadFtpConnections() {
			try {
				this.LoadingData = true;

				const res = await this.FtpConnectionsService.GetPaged({
					skip: 0,
					limit: 0,
					fields: "*",
				});

				this.items = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},

		async DeleteLicense(connectionId) {
			this.$log.debug(connectionId);
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete"),
					{ color: "error" }
				))
			) {
				return;
			}
			this.LoadingData = true;
			try {
				await this.FtpConnectionsService.Delete(connectionId);
				this.snackSuccess({ Text: this.$t("success.itemDelete") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotDeleteItem") });
			} finally {
				this.LoadingData = false;
			}
			await this.LoadFtpConnections();
		},
	},
};
</script>
<i18n>
{
	"it":
	{
		"systemSettings": "System Settings",
		"ftpConnections": "Impostazioni FTP"
	}
}
</i18n>
