const routes = [{
	path: "/Users",
	component: () => import(/* webpackChunkName: "userEditorViews" */ "./Index.vue")
},
{
	path: "/Users/UserList",
	component: () => import(/* webpackChunkName: "userEditorViews" */ "./UserLists.vue")
},
{
	path: "/Users/EditUser/:UserId",
	component: () => import(/* webpackChunkName: "userEditorViews" */ "./EditUser.vue")
},
{
	path: "/Users/Tags",
	component: () => import(/* webpackChunkName: "userEditorViews" */ "./Tags.vue")
}
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: "UserManager"
	};
}
);

export default routes;
