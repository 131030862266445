import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "@/plugins/vuetify";
import i18n from "./i18n";
import logger from "@/utilities/logger";
import VueGtag from "vue-gtag";
import LoadScript from "vue-plugin-load-script";

import "@/utilities/vueheadful";
import "@/utilities/veeValidate";

import ReadMore from "vue-read-more";

// import AccountService from "@/services/account"

import "@/filters/filters";
import { getParameterByName, routerGuardsSetup } from "./routerGuardsSetup";

import SkeletonTable from "@/components/Shared/UI/Skeleton/Table";
import SkeletonText from "@/components/Shared/UI/Skeleton/Text";
// import VueZoomer from "vue-zoomer"

Vue.use(LoadScript);
Vue.use(ReadMore);
Vue.component("skeleton-table", SkeletonTable);
Vue.component("skeleton-text", SkeletonText);

// Vue.use(VueZoomer)

Vue.config.productionTip = false;

async function applicationStart() {
	logger.$log.info("Application Start - Admin");

	try {
		// const applicationId = getParameterByName("ApplicationId");
		const applicationId = "2B576049-1DBF-42B4-98A1-544BFF2A7464";
		store.state.userData.selectedApplicationId = applicationId;

		const instantAccessToken = getParameterByName("InstantLoginToken");
		if (instantAccessToken) {
			await store.dispatch(
				"refreshTokenFromInstantLoginToken",
				instantAccessToken
			);
		}

		const selectedLanguage = getParameterByName("lang");
		if (selectedLanguage && ["it", "en"].includes(selectedLanguage)) {
			i18n.locale = selectedLanguage;
		}

		// try to refresh token
		await store.dispatch("RefreshAccessToken", true);

		setInterval(async () => {
			await store.dispatch("RefreshAccessToken", false);
		}, 30 * 60 * 1000);

		await store.dispatch("LoadUserData");
	} catch (error) {
		logger.$log.error("LoadUserData error:");
		logger.$log.error(error);
	}

	// router guards and application mount are activated after UserData is fetched from server
	routerGuardsSetup(router);

	if (process.env.NODE_ENV === "production") {
		Vue.use(
			VueGtag,
			{
				config: {
					id: "G-G3GKL9BQCF",
				},
			},
			router
		);
	}

	new Vue({
		router,
		store,
		render: (h) => h(App),
		i18n,
		vuetify,
	}).$mount("#app");

	window.addEventListener("storage", async () => {
		const token = JSON.parse(window.localStorage.getItem("user-token"));
		// await store.dispatch("SetRefreshToken", token);
		if (token) {
			store.commit("SET_AUTHTOKEN", token);
		} else {
			store.commit("SIGNOUT");
		}
	});
}
(async () => {
	await applicationStart();
})();
