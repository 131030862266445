import api from "@/services/api"
import store from "@/store"
import CrudClient from "@/services/CrudClient/";
import {
	HttpError
} from "@/services/HttpError";

// TODO: can we use CrudClient here _fetch?

export default {
	async signup(username, password) {
		var details = {
			username: username,
			password: password,
			grant_type: "password"
		};

		const formBody = _constructQueryString(details);

		const response = await fetch(api.tokenService, {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
			},
			body: formBody
		});

		if (response.ok || response.status === 400) {
			return await response.json();
		}

		let body = null;
		if (response.body instanceof ReadableStream) { body = await response.json(); }

		throw new HttpError({
			method: "POST",
			requestUrl: api.tokenService,
			postedData: null,
			status: response.status,
			statusText: response.statusText,
			body
		});
	},

	async refreshAccessToken(refreshToken) {
		var details = {
			refresh_token: refreshToken,
			grant_type: "refresh_token"
		};

		const formBody = _constructQueryString(details);

		const response = await fetch(api.tokenService, {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
			},
			body: formBody
		});

		if (response.ok || response.status === 400) {
			return await response.json();
		}

		let body = null;
		if (response.body instanceof ReadableStream) { body = await response.json(); }

		throw new HttpError({
			method: "POST",
			requestUrl: api.tokenService,
			postedData: formBody,
			status: response.status,
			statusText: response.statusText,
			body
		});
	},

	async revokeAccessToken(refreshToken) {
		const crudClient = new CrudClient("RefreshToken");

		return await crudClient.Delete(refreshToken, null, false, false);
	},

	async duplicateAccessToken(refreshToken) {
		const crudClient = new CrudClient("RefreshToken");

		return await crudClient.PostCustom(refreshToken, null, "Duplicate", true, false);
	},

	async refreshTokenFromInstantLoginToken(refreshToken) {
		const crudClient = new CrudClient("RefreshToken");

		return await crudClient.PostCustom(refreshToken, null, "RefreshTokenFromInstantLoginToken", true, false);
	},

	async loadUserDetails() {
		const crudClient = new CrudClient("UserDetails");

		return await crudClient.GetCustom(null, false);
	},

	async tryResetPassword(dto) {
		const response = await fetch(`${api.url}UserAccount/TryResetPassword`, {
			method: "POST",
			body: JSON.stringify(dto),
			credentials: "same-origin",
			headers: new Headers({
				"Content-Type": "application/json"
			})
		});

		if (response.ok) {

		} else if (response.status === 400) {
			return await response.json();
		} else {
			throw new Error(response.statusText);
		}
	},

	async resetPassword(username, newPassword, passwordResetToken) {
		const response = await fetch(`${api.url}UserAccount/ResetPassword`, {
			method: "POST",
			body: JSON.stringify({
				username,
				password: newPassword,
				token: passwordResetToken
			}),
			credentials: "same-origin",
			headers: new Headers({
				"Content-Type": "application/json"
			})
		});

		if (response.ok) {

		} else if (response.status === 400) {
			return await response.json();
		} else {
			throw new Error(response.statusText);
		}
	},

	async changePassword(oldPassword, newPassword) {
		const response = await fetch(`${api.url}UserAccount/ChangePassword`, {
			method: "POST",
			body: JSON.stringify({
				newPassword: newPassword,
				oldPassword: oldPassword,
			}),
			credentials: "same-origin",
			headers: new Headers({
				"Content-Type": "application/json",
				...store.getters.getAuthHeader
			})
		});

		if (response.ok) {

		} else if (response.status === 400) {
			return await response.json();
		} else {
			throw new Error(response.statusText);
		}
	},

	// For Uploading Profile Image
	async UpdateImage(imgFileName, imgFile) {
		var formData = new FormData();
		formData.append(imgFileName, imgFile, imgFileName);

		const response = await fetch(`${api.url}UserDetails/image`, {
			method: "POST",
			headers: new Headers({
				...store.getters.getAuthHeader
			}),
			body: formData
		});

		if (response.ok) {
			return await response.json();
		} else {
			throw new Error("Request failed");
		}
	},

	async DeleteImage() {
		const response = await fetch(`${api.url}UserDetails/image`, {
			method: "DELETE",
			headers: new Headers({
				...store.getters.getAuthHeader
			})
		});

		if (response.ok) {
			return await response.json();
		} else {
			throw new Error("Request failed");
		}
	},

	async Patch(dto) {
		const response = await fetch(`${api.url}UserDetails`, {
			method: "PATCH",
			headers: new Headers({
				"Content-Type": "application/json",
				...store.getters.getAuthHeader
			}),
			body: JSON.stringify(dto)
		});

		if (response.ok) {
			return await response.json();
		} else {
			throw new Error("Request failed");
		}
	},

	async ChangeEmail(dto) {
		const response = await fetch(`${api.url}UserAccount/ChangeEmail`, {
			method: "POST",
			headers: new Headers({
				"Content-Type": "application/json",
				...store.getters.getAuthHeader
			}),
			body: JSON.stringify(dto)
		});

		if (response.ok) {

		} else if (response.status === 400) {
			return await response.json();
		} else {
			throw new Error(response.statusText);
		}
	},

	async RequestMobileVerificationCode() {
		const response = await fetch(`${api.url}UserDetails/RequestMobileVerificationCode`, {
			method: "POST",
			headers: new Headers({
				"Content-Type": "application/json",
				...store.getters.getAuthHeader
			})
		});

		if (response.ok) {

		} else if (response.status === 400) {
			const resData = await response.json();
			throw new Error(resData);
		} else {
			throw new Error(response.statusText);
		}
	},

	async VerifyMobilePhone(dto) {
		const response = await fetch(`${api.url}UserDetails/VerifyPhoneNo`, {
			method: "POST",
			headers: new Headers({
				"Content-Type": "application/json",
				...store.getters.getAuthHeader
			}),
			body: JSON.stringify(dto)
		});

		if (response.ok) {

		} else if (response.status === 400) {
			const resData = await response.json();
			throw new Error(resData);
		} else {
			throw new Error(response.statusText);
		}
	},

	async GetMetadata() {
		const response = await fetch(`${api.url}UserDetails/Metadata`, {
			method: "GET",
			headers: new Headers({
				...store.getters.getAuthHeader
			})
		});

		if (response.ok) {
			return await response.json();
		} else {
			throw new Error(response.statusText);
		}
	}
}

// helper: for x-www-form-urlencoded body format
function _constructQueryString(details) {
	let formBody = [];
	for (const property in details) {
		// eslint-disable-next-line no-prototype-builtins
		if (details.hasOwnProperty(property)) {
			const encodedKey = encodeURIComponent(property);
			const encodedValue = encodeURIComponent(details[property]);
			formBody.push(encodedKey + "=" + encodedValue);
		}
	}
	formBody = formBody.join("&");
	return formBody;
}
