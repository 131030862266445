<template>
	<v-dialog
		:value="value"
		@input="$emit('input', $event)"
		max-width="500px"
		@keydown.esc="CloseDialog"
		persistent
		no-click-animation
	>
		<SimpleFormCard
			ref="formAddEditPhotographyLicense"
			:formContent="formAddEditPhotographyLicense"
			:metadata="metadata"
			:metadataLoaded="metadataLoaded"
			:mode="mode"
			@submitForm="AddOrSave"
			:showCancelButton="false"
			:locali18n="$i18n"
			:initialValues="itemDetails"
		>
			<template slot="card-title">
				<div
					class="
						primary
						white--text
						px-5
						py-3
						d-flex
						align-center
						justify-space-between
					"
				>
					<span class="headline" style="word-break: normal">{{
						mode == 1 ? $t("addLicenseDialog_Header") : $t("editLicenseDialog_Header")
					}}</span>
					<v-btn icon dark @click="CloseDialog">
						<v-icon>close</v-icon>
					</v-btn>
				</div>
			</template>
		</SimpleFormCard>
	</v-dialog>
</template>
<script>
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";
import metadata from "@/mixins/metadata";

export default {
	$_veeValidate: { validator: "AddEditLicense" },
	components: { SimpleFormCard },
	mixins: [metadata],
	data() {
		return {
			formAddEditPhotographyLicense: new CrudFormData(
				"formAddEditPhotographyLicense",
				[
					"Name",
					{
						type: "v-select",
						FieldName: "MediaTypeId",
						items: [],
						"item-text": "TypeName",
						"item-value": "MediaTypeId",
					},
					{
						type: "v-textarea",
						FieldName: "Description",
					},
					"NameEN",
					{
						type: "v-textarea",
						FieldName: "DescriptionEN",
					},
					{
						type: "v-checkbox",
						FieldName: "IsActive",
					},
					{
						type: "v-text-field",
						FieldName: "DefaultPrice",
						"input-type": "number",
					},
					{
						type: "v-select",
						FieldName: "AreaId",
						items: [],
						"item-text": "Name",
						"item-value": "AreaId",
					},

					{
						type: "v-text-field",
						FieldName: "ExpiresInMonths",
						"input-type": "number",
					},
				]
			),

			LoadingUsers: false,
			ClientAccounts: [],
			itemDetails: {},
			Areas: [],
			MediaTypes: [],
		};
	},
	props: {
		value: { type: Boolean, required: true },
		mode: { type: Number, required: true },
		LicenseId: { type: Number },
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			if (!val) this.ClearNewUserDialogForm();
		},
		LicenseId: async function (val) {
			this.$log.debug("watch LicenseId");
			this.$log.debug(val);

			if (val) {
				this.itemDetails = await this.PhotographyLicensesService.Get(val);
			}
		},
	},
	created() {
		this.PhotographyLicensesService = new CrudClient("SGP/PhotographyLicenses");
		this.PhotographyLicenseAreasService = new CrudClient(
			"SGP/PhotographyLicenseAreas"
		);

		this.MediaTypesService = new CrudClient("SGP/MediaTypes");
	},
	async mounted() {
		this.$log.debug("Add/Edit Photography Licenses Dialog mounted");
		await this.LoadMetadata(this.PhotographyLicensesService);
		const res = await this.PhotographyLicenseAreasService.GetPaged({
			limit: 0,
			skip: 0,
		});
		this.Areas = res.Data;

		const mediaTypesRes = await this.MediaTypesService.GetPaged({
			limit: 0,
			skip: 0,
		});
		this.MediaTypes = mediaTypesRes.Data;

		this.formAddEditPhotographyLicense.Fields.find(
			(f) => f.FieldName === "AreaId"
		).items = [...this.Areas];

		this.formAddEditPhotographyLicense.Fields.find(
			(f) => f.FieldName === "MediaTypeId"
		).items = [...this.MediaTypes];
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async AddOrSave(dto) {
			this.formAddEditPhotographyLicense.FormLoading = true;

			try {
				if (this.mode === 1) {
					await this.PhotographyLicensesService.Post(null, dto);
					this.snackSuccess({ Text: this.$t("common.success.addItem") });
				} else {
					await this.PhotographyLicensesService.Patch(this.LicenseId, dto, false);
					this.snackSuccess({ Text: this.$t("common.success.updateData") });
				}
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.formAddEditPhotographyLicense.FormLoading = false;
				this.CloseDialog();
				this.$emit("updated");
			}
		},

		ClearNewUserDialogForm() {
			if (this.$refs.formAddEditPhotographyLicense) {
				this.$refs.formAddEditPhotographyLicense.ResetForm();
			}
		},

		CloseDialog() {
			this.$emit("input", false);
		},
	},
};
</script>
<i18n>
{
	"it": {
		"addLicenseDialog_Header": "Nuova licenza",
		"editLicenseDialog_Header": "Modifica licenza",
		"formAddEditPhotographyLicense":
		{
			"IsActive": "Attivo?",
			"Name" : "Nome Licenza",
			"Description": "Descrizione",
			"NameEN" : "Nome Licenza (English)",
			"DescriptionEN": "Descrizione (English)",
			"DefaultPrice": "Prezzo predefinito",
			"ExpiresInMonths": "Durata (mesi)",
			"AreaId": "Luogo",
			"MediaTypeId": "Tipo contenuto"
		}
	}
}
</i18n>
