<template>
	<div>
		<confirm ref="confirm"></confirm>
		<vue-headful
			:title="$t('sgp.synchronizationTasks')"
			:description="$t('pageMetaDescription')"
		/>
		<v-breadcrumbs :items="breadcrumbsItems" class="pl-3">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2"
				>fas fa-caret-right</v-icon
			>
      </template>
		</v-breadcrumbs>

		<div class="elevation-5">
			<v-toolbar flat color="blue-grey lighten-4">
				<v-toolbar-title>{{ $t("sgp.synchronizationTasks") }}</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar>
			<div style="height: 0px; position: relative">
				<v-btn absolute @click="ShowNewDialog" dark fab top right color="green">
					<v-icon>add</v-icon>
				</v-btn>
			</div>

			<div class="pa-5">
				<AddEditSynchronizationTaskDialog
					v-model="showDetailsDialog"
					:mode="dialogMode"
					@updated="LoadSynchronizationTasks"
					:TaskId="editItemId"
				></AddEditSynchronizationTaskDialog>

				<v-progress-linear
					:indeterminate="true"
					v-show="LoadingData"
				></v-progress-linear>
				<v-row>
					<v-col
						cols="12"
						sm="6"
						lg="3"
						v-for="item in items"
						v-bind:key="item.TaskId"
					>
						<v-card class="mb-4">
							<v-card-title>{{ item.TaskName }}</v-card-title>
							<v-card-text class="text--primary">
								<span class="font-weight-bold pr-1"> {{ $t("sgp.event") }}: </span
								>{{ item.SGPEvent.Name }}
								<br />

								<span class="font-weight-bold pr-1">
									{{ $t("sgp.ftpConnection") }}: </span
								>{{ item.FtpConnection.Name }}
								<br />
								<span class="font-weight-bold pr-1">{{ $t("sgp.ftpFolder") }}:</span
								>{{ item.SyncFtpFolder }}
								<br />
								<span class="font-weight-bold pr-1"
									>{{ $t("sgp.syncTaskIsRunning") }}:</span
								>{{ item.IsRunning ? $t("running") : $t("stopped") }}
								<br />

								<span class="font-weight-bold pr-1">{{ $t("common.createDate") }}:</span
								>{{ item.CreateDate | formatLocalDateTime }}
								<br />
								<span class="font-weight-bold pr-1">{{ $t("lastSyncDate") }}:</span
								>{{ item.LastExecutionDate | formatLocalDateTime | emptyField }}
								<br />
							</v-card-text>
							<v-card-actions>
								<v-btn :disabled="LoadingData" icon @click="StartStopTask(item.TaskId)"
									><v-icon> fas fa-play</v-icon></v-btn
								>
								<v-spacer></v-spacer>

								<v-btn icon @click="DeleteItem(item.TaskId)"
									><v-icon>fas fa-trash</v-icon></v-btn
								>
								<v-btn icon @click="OpenEditDialog(item.TaskId)"
									><v-icon>fas fa-edit</v-icon></v-btn
								>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</div>
		</div>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import AddEditSynchronizationTaskDialog from "@/components/FtpConnection/AddEditSynchronizationTaskDialog.vue";
import Confirm from "@/components/Shared/Common/Confirm";

import { mapActions } from "vuex";

export default {
	components: {
		AddEditSynchronizationTaskDialog,
		Confirm,
	},

	data() {
		return {
			showDetailsDialog: false,
			dialogMode: 1,
			items: [],
			breadcrumbsItems: [],
			LoadingData: false,
			editItemId: null,
		};
	},
	watch: {
		// when dialog is closed reset id so we can reload values when re-open
		showDetailsDialog: async function (val) {
			this.$log.debug("showDetailsDialog:" + val);
			if (!val) {
				this.editItemId = null;
			}
		},
	},
	created() {
		this.StorageSynchronizationTasksService = new CrudClient(
			"SGP/FTP/StorageSynchronizationTasks"
		);
	},
	async mounted() {
		this.breadcrumbsItems.push(
			{
				text: this.$t("systemSettings"),
				disabled: false,
				exact: true,
				to: "/SystemSettings",
			},
			{
				text: this.$t("sgp.synchronizationTasks"),
				disabled: true,
				exact: true,
				to: "/SystemSettings/SynchronizationTasks",
			}
		);
		await this.LoadSynchronizationTasks();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		ShowNewDialog() {
			this.editItemId = null;
			this.dialogMode = 1;
			this.showDetailsDialog = true;
		},

		async OpenEditDialog(itemId) {
			this.editItemId = itemId;
			this.dialogMode = 2;
			this.showDetailsDialog = true;
		},

		async LoadSynchronizationTasks() {
			try {
				this.LoadingData = true;

				const res = await this.StorageSynchronizationTasksService.GetPaged({
					skip: 0,
					limit: 0,
					fields: "*, FtpConnection.Name, SGPEvent.*",
				});

				this.items = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},

		async DeleteItem(itemId) {
			this.$log.debug(itemId);
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete"),
					{ color: "error" }
				))
			) {
				return;
			}
			this.LoadingData = true;
			try {
				await this.StorageSynchronizationTasksService.Delete(itemId);
				this.snackSuccess({ Text: this.$t("success.itemDelete") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotDeleteItem") });
			} finally {
				this.LoadingData = false;
			}
			await this.LoadSynchronizationTasks();
		},

		async StartStopTask(taskId) {
			this.LoadingData = true;
			try {
				await this.StorageSynchronizationTasksService.Post(
					taskId,
					null,
					"Synchronize"
				);
				this.snackSuccess({ Text: this.$t("common.success.updateData") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.LoadingData = false;
			}

			// wait this.LoadSynchronizationTasks();
		},
	},
};
</script>
<i18n>
{
	"it":
	{
		"systemSettings": "System Settings",
		"running":"attivo",
		"stopped": "fermo",
		"lastSyncDate": "Ultima sincronizzazione"
	}
}
</i18n>
