<template>
	<div class="home">
		<vue-headful
			:title="$t('pageTitle')"
			:description="$t('pageMetaDescription')"
		/>
		<v-container>
			<v-row>
				<v-col cols="12" md="6">
					<!-- <v-img
						src="@/assets/img/logoSgp.png"
						contain
						height="130"
						width="200"
						v-if="$vuetify.breakpoint.xs"
					></v-img> -->
					<h1
						class="text-h4 text-center grey--text text--darken-1"
						style="margin-top: 100px"
					>
						Benvenuti in SGPix,
					</h1>
					<div class="text-h6 ma-8 text-center grey--text text--darken-1">
						la nuova piattaforma per la consegna veloce e automatica della immagini
						scattate dai fotografi di SGP Stefano Guindani Photo.
					</div>
					<div
						class="text-center mb-5 grey--text text--darken-1"
						style="margin-top: 100px"
					>
						<a href="https://www.facebook.com/sgpitalia" target="_blank">
							<v-icon size="22" color="mx-2">fab fa-facebook-f</v-icon>
						</a>
						<a
							class="mx-5"
							href="https://www.instagram.com/sgpitalia/"
							target="_blank"
						>
							<v-icon size="22">fab fa-instagram</v-icon></a
						>
						<a
							href="https://www.linkedin.com/company/sgp-stefano-guindani-photo/"
							target="_blank"
						>
							<v-icon size="22" color="mx-2">fab fa-linkedin-in</v-icon></a
						>
					</div>
					<div class="text-center text-body-2 grey--text text--darken-1">
						<span class="mr-3"> produzione@sgpitalia.com </span>
						<span class="mr-3">tel: +39 02 20480364</span>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<v-img
						class="rounded"
						:src="'https://sgpphoto.blob.core.windows.net/sgpweb/Home2.jpg'"
					></v-img>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "Home",
	components: {
	},
	data() {
		return {
			currentEvent: null,
		};
	},

	computed: {
		...mapGetters(["SasToken"]),
	},
	async mounted() {},
	methods: {
		changeVal(val) {
			this.$log.debug("change: " + val);
		},
	},
};
</script>
<i18n>
{
	"it":{
		"pageTitle": "SGPix by Stefano Guindani Photo",
		"pageMetaDescription": "Acquista le foto degli eventi"
	}
}
</i18n>
