<template>
	<v-dialog
		:value="value"
		@input="$emit('input', $event)"
		max-width="500px"
		@keydown.esc="CloseDialog"
		persistent
		no-click-animation
	>
		<SimpleFormCard
			ref="formAddEditFtpConnection"
			:formContent="formAddEditFtpConnection"
			:metadata="metadata"
			:metadataLoaded="metadataLoaded"
			:mode="mode"
			@submitForm="AddOrSave"
			:showCancelButton="false"
			:locali18n="$i18n"
			:initialValues="itemDetails"
		>
			<template slot="card-title">
				<div
					class="
						primary
						white--text
						px-5
						py-3
						d-flex
						align-center
						justify-space-between
					"
				>
					<span class="headline" style="word-break: normal">{{
						mode == 1
							? $t("addFtpConnection_Header")
							: $t("editFtpConnectionDialog_Header")
					}}</span>
					<v-btn icon dark @click="CloseDialog">
						<v-icon>close</v-icon>
					</v-btn>
				</div>
			</template>
		</SimpleFormCard>
	</v-dialog>
</template>
<script>
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import CrudFormData from "@/utilities/CrudFormData.js";
import metadata from "@/mixins/metadata";

export default {
	$_veeValidate: { validator: "AddEditFtpConnection" },
	components: { SimpleFormCard },
	mixins: [metadata],
	data() {
		return {
			formAddEditFtpConnection: new CrudFormData("formAddEditFtpConnection", [
				"Name",
				"Url",
				"Username",
				"Password",
			]),

			itemDetails: {},
		};
	},
	props: {
		value: { type: Boolean, required: true },
		mode: { type: Number, required: true },
		ConnectionId: { type: Number },
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			if (!val) this.ClearNewUserDialogForm();
		},
		ConnectionId: async function (val) {
			this.$log.debug("watch ConnectionId");
			this.$log.debug(val);

			if (val) {
				this.itemDetails = await this.FtpConnectionsService.Get(val);
			}
		},
	},
	created() {
		this.FtpConnectionsService = new CrudClient("SGP/FTP/Connections");
	},
	async mounted() {
		this.$log.debug("Add/Edit Ftp Connections Dialog mounted");
		await this.LoadMetadata(this.FtpConnectionsService);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async AddOrSave(dto) {
			this.formAddEditFtpConnection.FormLoading = true;

			try {
				if (this.mode === 1) {
					await this.FtpConnectionsService.Post(null, dto);
					this.snackSuccess({ Text: this.$t("common.success.addItem") });
				} else {
					await this.FtpConnectionsService.Patch(this.ConnectionId, dto, false);
					this.snackSuccess({ Text: this.$t("common.success.updateData") });
				}
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.formAddEditFtpConnection.FormLoading = false;
				this.CloseDialog();
				this.$emit("updated");
			}
		},

		ClearNewUserDialogForm() {
			if (this.$refs.formAddEditFtpConnection) {
				this.$refs.formAddEditFtpConnection.ResetForm();
			}
		},

		CloseDialog() {
			this.$emit("input", false);
		},
	},
};
</script>
<i18n>
{
	"it": {
		"addFtpConnection_Header": "Nuova connessione",
		"editFtpConnectionDialog_Header": "Modifica impostazioni",
		"formAddEditFtpConnection":
		{
			"Name" : "Nome Connessione",
			"Url": "Url",
			"Username": "Nome utente",
			"Password": "Password"
		}
	}
}
</i18n>
