<template>
	<v-snackbar
		top
		right
		multi-line
		:timeout="5000"
		v-model="showSnackbar"
		:color="SnackbarColor"
	>
		<v-row no-gutters class="my-2">
			<v-col class>
				<v-icon dark size="36" :color="SnackbarColor + ' lighten-5'">{{
					this.icon
				}}</v-icon>
			</v-col>
			<v-col cols="8" class="pl-3">
				<div class="snackbarHeader">{{ SnackbarHeader }}</div>
				<div>{{ SnackbarText }}</div>
			</v-col>
			<v-col class="text-right">
				<v-btn dark icon @click="showSnackbar = false">
					<v-icon dark>fas fa-times</v-icon>
				</v-btn>
			</v-col>
		</v-row>
	</v-snackbar>
</template>

<script>
export default {
	name: "CustomSnackBar",
	data() {
		return {
			showSnackbar: false,
			SnackbarText: "",
			SnackbarColor: "error",
			SnackbarHeader: "",
			icon: "",
		};
	},
	watch: {
		showSnackbar: function () {
			this.$emit("snackBarClosed");
		},
	},
	methods: {
		showNotification({ Text, Color, Header, Icon }) {
			this.showSnackbar = true;
			this.SnackbarText = Text;
			this.SnackbarColor = Color;
			this.SnackbarHeader = Header;
			this.icon = Icon;
		},

		success(text, header = "snackHeader.success") {
			this.showSnackbar = true;
			this.SnackbarText = text;
			this.SnackbarColor = "success";
			this.SnackbarHeader = header;
			this.icon = "fas fa-check";
		},

		info(text, header = this.$t("snackHeader.error")) {
			this.showSnackbar = true;
			this.SnackbarText = text;
			this.SnackbarColor = "info";
			this.icon = "far fa-info-circle";
			this.SnackbarHeader = header;
		},

		error(text, header = this.$t("snackHeader.info")) {
			this.showSnackbar = true;
			this.SnackbarText = text;
			this.SnackbarColor = "error";
			this.SnackbarHeader = header;
			this.icon = "fas fa-exclamation-triangle";
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.snackbarHeader {
	color: white;
	font-family: $font_Main;
	font-size: 1.3rem;
	font-weight: bold;
}
</style>
